/* eslint-disable no-unused-vars */
import React, { useEffect } from 'react';
import { MainHeading } from 'application/Components/UI/Heading/MainHeading';
import { SimpleDescription } from 'application/Components/UI/Heading/SimpleDescription';
import { Layout } from 'application/Components/UI/Layouts/Layout';
import { UPDATE_PROFILE_SUCCESS, UPDATE_PROFILE_TITLE } from 'application/enum';
import { Button } from 'application/Components/UI/Input/Button';
import { useLocation, useNavigate } from 'react-router-dom';
import { paths } from 'constants';
import { finalize } from 'api/finalize';
import { Box } from '@mui/material';
import useAdobeDataLayer from 'hooks/useAdobeDataLayer';
import { useSelector } from 'react-redux';
import { loginSelector } from 'store/reducers/login/login.slice';

export const UpdateProfileSuccess = () => {
  const adobeDataLayer = useAdobeDataLayer();
  const navigate = useNavigate();
  const location = useLocation();
  const state = location.state || null;

  const { username } = useSelector(loginSelector);

  useEffect(() => {
    const isValid = state === UPDATE_PROFILE_SUCCESS;
    if (!isValid) {
      navigate(paths.LOGIN);
    }
  }, []);

  const clickSubmit = () => {
    adobeDataLayer.loginSuccessfulDL(username.value, 'loginUpdateProfile');
    finalize();
  };

  return (
    <Layout>
      <Box>
        <MainHeading sx={{ marginBottom: '32px' }}>
          {UPDATE_PROFILE_TITLE}
        </MainHeading>
        <SimpleDescription>{UPDATE_PROFILE_SUCCESS}</SimpleDescription>
        <Button
          sx={{ marginTop: '48px', width: '400px' }}
          onClick={clickSubmit}
        >
          My account
        </Button>
      </Box>
    </Layout>
  );
};
