/* eslint-disable no-useless-catch */
/* eslint-disable no-unused-vars */
import { createAsyncThunk } from '@reduxjs/toolkit';
import { getNonPOTJwt, searchUser, setMigrationComplete } from 'api/searchUser';
import { getUserMigrationStatus, uidValidationLDAPApi } from 'api/user.api';
import { setContactInfo, setLoginPageState } from '../session/session.slice';
import { finalize } from 'api/finalize';
import { authenticate, authenticateV2 } from 'api/authenticate';
import { passwordValidation } from 'api/passwordValidation';
import { paths } from '../../../constants/paths';
import { authisamResetPassword } from 'api/authisamResetPassword';
import { codes } from 'constants';
import { DEFAULT_ERROR } from 'application/enum';
import { authErrorHelper, authtypes } from '../../../constants';
import { usrParticipantPlansCacheApi } from '../../../api/user.api';
import { setVerifyAnotherWayState } from '../signinAnotherWay/signinAnotherWay.slice';
import Utility from 'utils/Utility';

export const usernameEnteredAction = createAsyncThunk(
  'login/usernameEnteredAction',
  async (_payload, { getState, dispatch }) => {
    const state = getState();
    const username = state.login?.username?.value.trim();

    const apis = [searchUser(username), uidValidationLDAPApi(username, state)];

    const responses = await Promise.all(apis);
    const [searchUserRes, uidValRes] = responses;

    const anotherWayDetails = {
      username,
      chosenAuthType: searchUserRes.another_way || authtypes.PASSWORD,
    };
    dispatch(setLoginPageState(anotherWayDetails));
    // Set whether verify another way should be displayed as an option or not
    dispatch(setVerifyAnotherWayState({ enabled: searchUserRes.another_way }));

    return {
      searchUserRes,
      uidValRes,
    };
  },
);

export const usernameEnteredActionRps = createAsyncThunk(
  'login/usernameEnteredActionRps',
  async (_payload, { getState, dispatch }) => {
    try {
      const state = getState();
      const username = state.login?.username?.value.trim();

      // should run in the background
      usrParticipantPlansCacheApi({ userid: username });

      const migrationRes = await getUserMigrationStatus(username, state);

      const migrationFlags = {
        isMigrationPending: migrationRes?.Code === '0001',
        isEmailConflict: migrationRes.Details?.emailConflict === 'true',
        isMobileConflict: !migrationRes.Details?.mobile,
        isUidConflict: migrationRes.Details?.uidConflict === 'true',
        isNonPOTFlow: !!(
          +migrationRes.code === +codes.UNKNOWN_ERROR ||
          migrationRes.Details?.isNonPOTFlow
        ),
      };

      sessionUpdateHelper(dispatch, username, state);

      return { ...migrationRes, ...migrationFlags };
    } catch (error) {
      console.error('Error getUserMigrationStatus', error);
      throw DEFAULT_ERROR;
    }
  },
);

const sessionUpdateHelper = async (dispatch, username, state) => {
  return new Promise(() => {
    const dat = {
      username,
      chosenAuthType: 1,
    };

    dispatch(setLoginPageState(dat));
  });
};

export const onSubmitPassword = createAsyncThunk(
  'login/onSubmitPassword',
  async (payload, { getState, _dispatch }) => {
    const store = getState();

    const { sessionid } = store.session.value;
    const password = store.login?.password?.value.trim();
    const username = store.login?.username?.value.trim();
    const { adobeDataLayer, navigate } = payload;
    const queryparams = window.location.search;
    try {
      await authenticate(password, adobeDataLayer, username);
      // If login was successful with no MFA challenge, redirect to backend's /redirect endpoint to finish the flow
      finalize();
    } catch (error) {
      let code = error.code ? error.code : error;
      switch (code) {
        case codes.MFA_CHALLENGE:
          // Saves the login page's state, in case the user wants to navigate back after being taken to the MFA page
          // saveState(authtypes.PASSWORD);

          navigate({
            pathname: paths.LOGIN_MFA,
            search: queryparams,
          });
          return;
        // If an Access Denied response was received, navigate to the Access Denied page
        case codes.DENY_ACCESS:
          navigate(paths.ACCESS_DENIED);
          return;
        // If an Account Locked response was received, navigate to the Account Locked page
        case codes.ACCOUNT_LOCKED:
          navigate({ pathname: paths.ACCOUNT_LOCKED, search: queryparams });
          return;
        // User has UnverifiedGroup in ISAM, redirecting user to policy verification page.
        case codes.UNVERIFIED_USER:
          Utility.openLink(error.details?.verifyIdentityRedirectUrl);
          return;
        // If none of the above responses were received, display the error message
        default:
          return error;
      }
    }
  },
);

export const onSubmitNonPotAction = createAsyncThunk(
  'login/onSubmitNonPotAction',
  async (payload, { getState, _dispatch }) => {
    const store = getState();
    const username = store.login?.username?.value.trim();
    const { adobeDataLayer } = payload;

    const { sessionid } = store.session.value;
    const password = store.login?.password?.value.trim();
    const isNonPOTFlow = true;

    adobeDataLayer.loginSuccessfulDL(username, 'login');
    const res = await getNonPOTJwt(sessionid, password);
    const { jwt, wsj_auth_value } = res.data.details;
    finalize(jwt, wsj_auth_value, isNonPOTFlow);
  },
);

export const onSubmitPotAction = createAsyncThunk(
  'login/onSubmitPotAction',
  async (payload, { getState, dispatch }) => {
    try {
      const store = getState();

      const sessionid = store.session.value;
      const appid = store.appSettings?.value?.appid;
      const password = store.login?.password?.value.trim();
      const username = store.login?.username?.value.trim();
      const migrationDetails = store.login?.migrationDetails;
      const {
        isMigrationPending,
        isEmailConflict,
        isMobileConflict,
        isUidConflict,
      } = migrationDetails;

      const { adobeDataLayer, navigate } = payload;

      const authRes = await authenticateV2(
        sessionid.sessionid,
        password,
        username,
        adobeDataLayer,
      );

      // FOR MFA
      mfaHelper(authRes, dispatch);

      if (authRes.code !== codes.SUCCESS) {
        const authErrorMessage = authErrorHelper(authRes, navigate);
        if (authErrorMessage === 'stopExecution') return;
        throw authErrorMessage;
      }

      if (!isMigrationPending) {
        adobeDataLayer.loginSuccessfulDL(username, 'login');
        finalize();
        return;
      }

      // conflict resolution logic
      let errorConflits = [];

      const passValPayload = { userid: username, sessionid };
      const validatePassword = await passwordValidation(passValPayload);
      const isValidPassword = validatePassword.data.code == '0000';
      if (!isValidPassword) {
        errorConflits.push('password');
      }

      if (isEmailConflict) errorConflits.push('email');
      if (isMobileConflict) errorConflits.push('mobileNumber');
      if (isUidConflict) errorConflits.push('username');

      const conflictsAreThere = errorConflits.length != 0;
      if (conflictsAreThere) {
        navigateToResolution(navigate, errorConflits, username, password);
        return;
      }

      // if no conflicts logic
      const passwordResetIsam = {
        appId: appid,
        UserName: username,
        Password: password,
      };
      const resetPasswordRes = await authisamResetPassword(passwordResetIsam);

      const resetPasswordFailed = resetPasswordRes.code !== '0000';
      if (resetPasswordFailed) {
        navigate(paths.DEFAULT_ERROR);
        return;
      }

      await setMigrationComplete(username);

      // generate access token and set in redis cache
      const authResForAccessToken = await authenticateV2(
        password,
        adobeDataLayer,
        username,
      );

      if (authResForAccessToken.code !== codes.SUCCESS) {
        const authErrorMessage = authErrorHelper(
          authResForAccessToken,
          navigate,
        );

        if (authErrorMessage === 'stopExecution') return;
        throw authErrorMessage;
      }
      adobeDataLayer.loginSuccessfulDL(username, 'login');
      finalize();
    } catch (error) {
      console.error(`Error:`, error);
      throw error;
    }
  },
);

export const onSubmitSprntAction = createAsyncThunk(
  'login/onSubmitSprntAction',
  async (_payload, { getState, dispatch }) => {
    const store = getState();
    const { sessionid } = store.session.value;
    const password = store.login?.password?.value.trim();
    const username = store.login?.username?.value.trim();
    const migrationDetails = store.login?.migrationDetails;
    const { isMigrationPending } = migrationDetails;

    const isNonPOTFlow = true;
    const { adobeDataLayer, navigate } = _payload;

    try {
      const authRes = await authenticateV2(
        sessionid,
        password,
        username,
        adobeDataLayer,
      );

      // FOR MFA
      mfaHelper(authRes, dispatch);
      if (authRes.code !== codes.SUCCESS) {
        const authErrorMessage = authErrorHelper(authRes, navigate);
        if (authErrorMessage === 'stopExecution') return;
        throw authErrorMessage;
      }

      if (!isMigrationPending) {
        const res = await getNonPOTJwt(sessionid, password);
        const { jwt, wsj_auth_value } = res.data.details;
        finalize(jwt, wsj_auth_value, isNonPOTFlow);
      }
      return 'Success';
    } catch (error) {
      throw error;
    }
  },
);

export const navigateToResolution = (
  navigate,
  errorConflits,
  username,
  password,
) => {
  const url = paths.UPDATE_PROFILE;

  const userDetails = { username, password };
  const payload = {
    errorConflits,
    userDetails,
    rps_userid: username,
  };

  navigate(url, {
    state: payload,
  });
};

export const navigateToPassword = createAsyncThunk(
  'login/navigateToPassword',
  (state, action) => {
    const { navigate } = action.payload;
    const queryParams = window.location.search;
    navigate(`${paths.LOGIN}${queryParams}`);
  },
);

const mfaHelper = (authRes, dispatch) => {
  if (authRes.details?.email || authRes.details?.mobile) {
    dispatch(
      setContactInfo({
        email: authRes.details.email,
        mobile: authRes.details.mobile,
      }),
    );
  }
};
