export const loadOverlayStyles = {
  spinnerStyles: {
    width: '150px',
    '& svg circle': {
      stroke: '#003c70',
    },
  },
  overlayStyles: {
    height: '100vh',
    width: '100vw',
    background: '#F1F5F6',
    fontWeight: 'bold',
    color: '#282B3E',
    position: 'fixed',
    zIndex: '1000',
  },
};
