import { createAsyncThunk } from '@reduxjs/toolkit';
import { getAuthType } from 'api/getAuthType';
import { authtypes } from 'constants';
import { paths } from 'constants';
import { setError } from '../login/login.slice';
import { setChosenAuthType } from '../session/session.slice';
import { authenticateAnotherWay } from 'api/authenticate';
import { codes } from 'constants';

export const clickSigninAnotherWayAction = createAsyncThunk(
  'signinAnotherWay/clickSigninAnotherWayAction',
  async (payload, { getState, dispatch }) => {
    const { navigate } = payload;

    try {
      const res = await getAuthType();

      const newVerifyAnotherWayState = {
        // Set max auth type (SSN or SQA) for this user from the API response
        maxAuthType: res.maxAuthType,

        // If user doesn't have SSN or SQA, disable the verify another way option
        enabled: res.maxAuthType !== authtypes.PASSWORD,

        // If max auth type is SQA, save the indices of the questions they need to answer
        sqaIndices: res.questions || [],
      };

      // If user has SSN or SQA, redirect to the screen to enter those details
      if (res.maxAuthType !== authtypes.PASSWORD) {
        navigateToVerifyAnotherWay(navigate, res.maxAuthType);
      }

      return newVerifyAnotherWayState;
    } catch (error) {
      dispatch(setError({ password: error }));
      return error;
    }
  },
);

const navigateToVerifyAnotherWay = (navigate, maxAuthType) => {
  const { SSN, SQA } = authtypes;
  let authType = null;

  if (maxAuthType === SSN) authType = SSN;
  else if (maxAuthType === SQA) authType = SQA;

  const queryparams = window.location.search;
  navigate(`${paths}${queryparams}&authType${authType}`);
};

export const onSubmitSqaAction = createAsyncThunk(
  'signinAnotherWay/onSubmitSqaAction',
  async (payload, { getState, dispatch }) => {
    const { sqaAnswers, navigate, sqaErrors } = payload;

    try {
      await authenticateAnotherWay(sqaAnswers);

      dispatch(setChosenAuthType(authtypes.SQA));

      // If SSN was entered correctly, navigate to the MFA page
      navigate(`${paths.LOGIN_MFA}${window.location.search}`);
    } catch (error) {
      const searchParams = location.search;

      switch (error) {
        // If an Access Denied response was received, navigate to the Access Denied page
        case codes.DENY_ACCESS:
          navigate(`${paths.ACCESS_DENIED}${searchParams}`);
          break;
        // If an Account Locked response was received, navigate to the Account Locked page
        case codes.ACCOUNT_LOCKED:
          navigate(`${paths.ACCOUNT_LOCKED}${searchParams}`);
          break;
        // If none of the above responses were received, display the error message
        default: {
          const errDat = {
            ...sqaErrors,
            general: error,
          };
          return errDat;
        }
      }
    }
  },
);
