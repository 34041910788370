export const authenticateReq = (session, password, username) => {
  return {
    session,
    password,
    userid: username,
  };
};

export const authenticateAuthisamReq = (userid, password) => {
  return {
    userid,
    password,
  };
};

export const authenticateAnotherWayReq = (session, authvalue) => {
  return {
    session,
    authvalue,
  };
};
