export const VERIFY_YOUR_IDENTITY = 'Verify your identity';
export const STEP_ONE_DESCRIPTION =
  "We're committed to protecting your personal information and use security measures and safeguards to keep your personal information safe. Please choose one of the contact methods below to receive a security code: ";
export const NO_PH_NO_EMAIL_DESC =
  "We're committed to protecting your personal information and use security measures and safeguards to keep your personal information safe. Because we don't have your current, validated contact information on file, we are not able to further validate your access.";
export const UPDATE_INFO =
  'Please update your information with a Participant Services Representative by calling 1-800-294-3575. Representatives are available 8 am to 10 pm ET, Monday-Friday New York Stock Exchange business days.';
export const NO_ACCESS_CONTACT_US =
  'If you no longer have access to any of the contact methods, please';
export const DID_NOT_RECEIVED_CODE =
  'If you didn’t receive your security code, select “Resend security code” to receive a new security code or';
export const UNABLE_TO_SEND_CODE_SECURITY_REASONS =
  'For security purposes, we are unable to send any more codes.';
export const MFA_RATE_LIMIT_REACHED_DESCRIPTION =
  'We’re sorry, but you’ve exceeded the maximum attempts to verify your identity. Your account has been temporarily locked for your protection. Please wait 30 minutes after your account was locked before you sign in again.';
