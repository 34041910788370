import getChatbotToken from 'api/chatbotToken';
import { paths } from 'constants';

const CHATBOT_DIV = 'chatBotDiv';
const CHATBOT_SCRIPT_ID = 'chatBotScript';
export async function loadChatBot(data) {
  const chatBotLOB = data.lob;
  const isChatBotFlagEnabled = process.env.REACT_APP_CHATBOT_FLAG === 'enabled';
  const currentPath = window.location.pathname;
  if (
    !isChatBotFlagEnabled ||
    chatBotLOB == null ||
    currentPath.includes(paths.CUSC_MFA)
  ) {
    window.document.getElementById(CHATBOT_DIV).style.display = 'none';
    return;
  }

  try {
    //{ token, directLineApi, subscriptionKey, speechHostname} needed
    const chatSession = await getChatbotToken(chatBotLOB);
    if (!chatSession) {
      window.document.getElementById(CHATBOT_DIV).style.display = 'none';
      return;
    }
    window.document.getElementById(CHATBOT_DIV).style.display = 'block';

    /** Init instance of WebChat UI **/
    let jhwebchat = new window.JHWebChat({
      webchatmode: 'bot',
      directlinetoken: chatSession.token,
      directLineAPI: chatSession.directLineApi,
      channeldata: 'LineOfBusiness:' + data.channelData, // 'LTC' or 'LIFE' or 'Vitality'
      subscriptionKey: chatSession.subscriptionKey,
      speechHostname: chatSession.speechHostname,
      micToggle: false,
      livechatenv: process.env.REACT_APP_ENV
        ? process.env.REACT_APP_ENV
        : 'dev', // 'dev' or 'prod'
    });
    window.jhwebchat = jhwebchat;

    const existingScript = document.getElementById(CHATBOT_SCRIPT_ID);
    if (!existingScript) {
      const script = document.createElement('script');
      script.type = 'text/javascript';
      script.id = CHATBOT_SCRIPT_ID;
      window.document.head.appendChild(script);
    }
  } catch (error) {
    console.log('In loadChatBot:error==', error);
  }
}

export function unloadChatBot() {
  try {
    const existingScript = document.getElementById(CHATBOT_SCRIPT_ID);
    if (existingScript) {
      window.jhwebchat.destroy();
      window.jhwebchat = null;
      window.document.head.removeChild(existingScript);
    }

    const chatBotEle = window.document.getElementById(CHATBOT_DIV);
    if (chatBotEle) {
      chatBotEle.parentElement.removeChild(chatBotEle);
    }
  } catch (error) {
    console.log('In loadChatBot:error==', error);
  }
}

export function initJHChatBotBundle(callback) {
  const existingScript = document.getElementById(CHATBOT_SCRIPT_ID);
  if (!existingScript) {
    const script = document.createElement('script');
    script.type = 'text/javascript';
    script.id = CHATBOT_SCRIPT_ID;
    script.src = 'https://partnerlink.jhancock.com/js/jh-webchat.bundle.js';
    script.onload = function () {
      callback();
    };
    window.document.head.appendChild(script);
  }
}

const ChatBotScript = {
  initJHChatBotBundle,
  loadChatBot,
};

export default ChatBotScript;
