import * as React from 'react';
import { Typography } from '@mui/material';
import { colors } from '@manulife/mux';

// Getting used in Sidebar as Icon Lbels

export const MiniDescription = ({ children, sx = {} }) => {
  return <Typography sx={{ ...style, ...sx }}>{children}</Typography>;
};

const style = {
  color: colors.white,
  fontWeight: 400,
  lineHeight: '1rem',
  fontSize: '0.813rem',
};
