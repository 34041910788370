export const storageNames = {
  REMEMBER_USERNAME_VALUE: 'remember_username_value',
  REMEMBER_USERNAME_ACTIVE: 'remember_username_active',

  RSA_COOKIE: 'rsa_cookie',
  DEVICE_TOKEN_COOKIE: 'DeviceTokenCookie',

  // AMWEBJCT!%2Fauthb! is added by IBM when the cookie is set in a response by an ISAM junction.
  // Since we set this cookie in the response from /authb/redirect/mfa, AMWEBJCT!%2Fauthb! is added to the cookie name
  CUSC_TOKEN: 'AMWEBJCT!%2Fauthb!cusc_mfa_token',
  // On some instances the above doesn't get added. Use this value as a backup
  CUSC_TOKEN_PLAIN: 'cusc_mfa_token',
  // Since we set this cookie in the response from /authb/redirect/mfa, AMWEBJCT!%2Fauthb! is added to the cookie name
  CSL_TOKEN: 'AMWEBJCT!%2Fauthb!csl_token',
  // On some instances the above doesn't get added. Use this value as a backup
  CSL_TOKEN_PLAIN: 'csl_token',
};
