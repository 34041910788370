import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  value: {
    width: window.innerWidth,
  },
};

export const windowSlice = createSlice({
  name: 'window',
  initialState,
  reducers: {
    setWidth: (state, action) => {
      state.value = {
        ...state.value,
        width: action.payload,
      };
    },
  },
});

export const { setWidth } = windowSlice.actions;
export const selectWindow = (state) => state.window.value;
export default windowSlice.reducer;
