import { codes } from './codes';
import { paths } from './paths';
import { DEFAULT_ERROR } from '../application/enum/common.enum';

export const authErrorHelper = (error, navigate) => {
  const stopExecution = 'stopExecution';
  const queryparams = window.location.search;

  switch (error.code) {
    case codes.MFA_CHALLENGE:
      navigate(`${paths.LOGIN_MFA}${queryparams}`);
      return stopExecution;

    // If an Access Denied response was received, navigate to the Access Denied page
    case codes.DENY_ACCESS:
      navigate(`${paths.ACCESS_DENIED}${queryparams}`);
      return stopExecution;

    // If an Account Locked response was received, navigate to the Account Locked page
    case codes.ACCOUNT_LOCKED:
      navigate(`${paths.ACCOUNT_LOCKED}${queryparams}`);
      return stopExecution;

    // This error needs to removed later. This is not in use
    case codes.INDETERMINANT_ERROR:
      navigate(`${paths.INDETERMINANT_ERROR}${queryparams}`);
      return stopExecution;

    case codes.INCORRECT_CREDENTIALS:
      return 'Your username/password combination isn’t correct. Password is case sensitive. Please try again.';

    case codes.RECENTLY_USED_PASSWORD:
      return DEFAULT_ERROR;

    // If none of the above responses were received, display the error message
    default:
      navigate(`${paths.DEFAULT_ERROR}${queryparams}`);
      return stopExecution;
  }
};
