import React, { Suspense } from 'react';
import { Routes, Route } from 'react-router-dom';
import LazyLoadRetry from './utils/LazyLoadRetry';
import LoadOverlay from './application/Components/Loaders/LoadOverlay';
import { NavigateWithQuery } from './utils/NavigateWithQuery';
import { paths } from './constants';
import { UpdateProfileSuccess } from 'application/Pages/UpdateProfile/UpdateProfileSuccess';

const RoutesLocal = () => {
  const Login = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/Login/Login')),
  );

  const SignInAnotherWay = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/SigninAnotherWay/SigninAnotherWay'),
    ),
  );

  const MFA = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/MFA/MFA')),
  );

  const NonMigratedMfa = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/MFA/MFA')),
  );

  const SSO = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/SSO/SSO')),
  );

  const AccountLocked = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/Errors/AccountLocked/AccountLocked'),
    ),
  );

  const NotAuthorized = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/Errors/NotAuthorized')),
  );

  const DefaultError = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/Errors/DefaultError/DefaultError'),
    ),
  );

  const ExpiredLink = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/Errors/ExpiredLink')),
  );

  const PhoneVerificationFail = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/Errors/PhoneVerificationFail'),
    ),
  );

  const UpdateProfile = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/UpdateProfile/UpdateProfile'),
    ),
  );

  const IndeterminantError = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/IndeterminantError/IndeterminantError'),
    ),
  );

  const VerifyPhone = React.lazy(() =>
    LazyLoadRetry(() => import('./application/Pages/VerifyPhone/VerifyPhone')),
  );

  const IntermediateLogin = React.lazy(() =>
    LazyLoadRetry(
      () => import('./application/Pages/Decision/IntermediateLogin'),
    ),
  );

  return (
    <Suspense fallback={<LoadOverlay />}>
      <Routes>
        <Route
          exact
          path="/"
          element={<NavigateWithQuery to={paths.LOGIN} />}
        />
        <Route
          path={paths.BASE_PATH}
          element={<NavigateWithQuery to={paths.LOGIN} />}
        />
        <Route path={paths.LOGIN} element={<Login />} />
        <Route
          path={paths.SIGN_IN_ANOTHER_WAY}
          element={<SignInAnotherWay />}
        />
        <Route path={paths.LOGIN_MFA} element={<MFA standaloneMFA={false} />} />
        <Route path={paths.MFA} element={<MFA standaloneMFA={true} />} />
        <Route path={paths.CUSC_MFA} element={<MFA standaloneMFA={true} />} />
        <Route
          path={paths.POST_LOGIN_MFA}
          element={<MFA standaloneMFA={true} />}
        />
        <Route
          path={paths.NON_MIGRATED_MFA}
          element={<NonMigratedMfa standaloneMFA={true} />}
        />
        <Route path={paths.SSO} element={<SSO />} />
        <Route path={paths.SSO_MFA} element={<MFA standaloneMFA={false} />} />
        <Route path={paths.ACCOUNT_LOCKED} element={<AccountLocked />} />
        <Route path={paths.ACCESS_DENIED} element={<NotAuthorized />} />
        <Route path={paths.DEFAULT_ERROR} element={<DefaultError />} />
        <Route path={paths.PHONE_VERIFICATION} element={<VerifyPhone />} />
        <Route path={paths.EXPIRED_LINK} element={<ExpiredLink />} />
        <Route
          path={paths.PHONE_VERIFICATION_FAIL}
          element={<PhoneVerificationFail />}
        />

        {/* Rps */}
        <Route path={paths.UPDATE_PROFILE} element={<UpdateProfile />} />
        <Route
          path={paths.UPDATE_PROFILE_SUCCESS}
          element={<UpdateProfileSuccess />}
        />
        <Route
          path={paths.INDETERMINANT_ERROR}
          element={<IndeterminantError />}
        />

        <Route
          path={paths.INTERMEDIATE_LOGIN}
          element={<IntermediateLogin />}
        />
      </Routes>
    </Suspense>
  );
};

export default RoutesLocal;
