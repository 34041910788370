export const validateMobileNumber = (number) => {
  const regex = /^(?:\+1\s?)?\(?(?:\d{3})\)?[\s.-]?(?:\d{3})[\s.-]?(?:\d{4})$/;
  return regex.test(number);
};

export const validateEmail = (email) => {
  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return regex.test(email);
};

export const extractNumbers = (number) => {
  return number.replace(/\D/g, '');
};

export const waitForAsync = (time) => {
  return new Promise((resolve) => {
    setTimeout(resolve, time);
  });
};

export async function hashSHA256(stringInput) {
  // create a TextEncoder instance, used to encode string to Unit8Array (byte array)
  const encoder = new TextEncoder();

  // encode string to Unit8Array
  const encoded = encoder.encode(stringInput);

  // use the subtleCrypto api to generate SHA-256 hash for the encoded variable
  const hashBuffer = await crypto.subtle.digest('SHA-256', encoded);

  // convert ArrayBuffer to a Uint8Array
  const hashArray = Array.from(new Uint8Array(hashBuffer));

  // map each byte into Uint8Array to a hexaDecimal string and ensuring each byte is 2 characters long
  const hashHex = hashArray
    .map((byte) => byte.toString(16).padStart(2, '0'))
    .join('');

  return hashHex;
}
