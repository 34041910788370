import { paths } from '../../../constants';

export const inputObj = {
  value: '',
  loading: false,
  error: null,
};

export const signInSteps = {
  username: 0,
  password: 1,
  sqa: 2,
  ssn: 3,
};

// in non-pot error flow
export const rpsLoginErrors = {
  CAS_LOCKOUT_WARNING: {
    type: 'error',
    errorMessage:
      'Your username/password combination isn’t correct. Password is case sensitive. Please try again.',
  },
  CAS_USER_LOCKED: {
    type: 'navigate',
    path: paths.ACCOUNT_LOCKED,
  },
  CAS_SYSTEM_ERROR: {
    type: 'navigate',
    path: paths.DEFAULT_ERROR,
  },
  CAS_INVALID_CREDENTIALS: {
    type: 'error',
    errorMessage:
      'Your username/password combination isn’t correct. Password is case sensitive. Please try again.',
  },
  CAS_SIG_USER_PASSWORD_RESET_IN_PROCESS: {
    type: 'navigate',
    path: paths.ACCOUNT_LOCKED,
  },
  CAS_AUTH_TIMEOUT: {
    type: 'navigate',
    path: paths.DEFAULT_ERROR,
  },
};

export const defaultRpsErrorObj = {
  type: 'navigate',
  path: paths.DEFAULT_ERROR,
};
