import devicePrint from 'utils/scripts/rsa.js';
import { rsaCookie } from 'utils/storage';

const cookie = rsaCookie.get();

export const searchUserReq = (
  session,
  userid,
  ipAddress,
  searchOnly,
  appid,
) => {
  if (searchOnly) {
    return {
      session,
      userid,
      searchOnly,
    };
  }

  return {
    session,
    userid,
    deviceRequest: {
      devicePrint,
      ipAddress,
      userAgent: navigator.userAgent,
      rsaCookie: cookie,
    },
  };
};

export const migrationReq = (session, userid, ipAddress, appid) => {
  return {
    session,
    userid,
    appid,
    deviceRequest: {
      devicePrint,
      ipAddress,
      userAgent: navigator.userAgent,
      rsaCookie: cookie,
    },
  };
};
