import { configureStore } from '@reduxjs/toolkit';
import sessionReducer from './reducers/session/session.slice';
import appSettingsReducer from './reducers/appSettings/appSettings.slice';
import loginSlice from './reducers/login/login.slice';
import errorsSlice from './reducers/errors/errors.slice';
import { maintenanceReducer } from './reducers/maintenance/maintenance.slice';
import { rtkqueryApi } from './reducers/maintenance/maintenance.action';
import globalsSlice from './reducers/global/globals.slice';
import signinAnotherWaySlice from './reducers/signinAnotherWay/signinAnotherWay.slice';
import windowReducer  from './reducers/functions/window.slice';

export default configureStore({
  reducer: {
    session: sessionReducer,
    appSettings: appSettingsReducer,
    login: loginSlice,
    errors: errorsSlice,
    maintenance: maintenanceReducer,
    globals: globalsSlice,
    signinAnotherWay: signinAnotherWaySlice,
    window: windowReducer,
    // add RTKquery reducer
    [rtkqueryApi.reducerPath]: rtkqueryApi.reducer,
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware().concat(rtkqueryApi.middleware),
});
