import React from 'react';
import { Typography } from '@mui/material';

const DefaultDisclosure = ({ styles, year }) => {
  return (
    <>
      <Typography sx={styles.text}>
        © {year} John Hancock Life Insurance Company (U.S.A.). All rights
        reserved.
      </Typography>
    </>
  );
};

export default DefaultDisclosure;
