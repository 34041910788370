import { urls, codes } from 'constants';
import { searchUserReq, migrationReq } from './model/searchUserReq';
import axios from 'axios';
import { DEFAULT_ERROR } from 'application/enum';
import httpRetry from './apiUtils/fetchWithRetry';

const headers = {
  'Content-Type': 'application/json',
};

export const getUserMigrationStatus = async (userid, state) => {
  try {
    //   Get sessionid  from redux
    const { sessionid, ipAddress } = state.session.value;
    const { appid } = state.appSettings?.value || {};
    const url = urls.USER_MIGRATION_STATUS;

    // Create the request body
    const requestBody = migrationReq(sessionid, userid, ipAddress, appid);

    // Make API call to backend to initialize the cache session
    const response = await httpRetry.post(url, requestBody, {
      headers,
    });
    return response?.data || null;
  } catch (error) {
    return {
      code: codes.UNKNOWN_ERROR,
      message: DEFAULT_ERROR,
    };
  }
};

export const uidValidationLDAPApi = async (userid, state) => {
  // Get sessionid  from redux
  const { sessionid, ipAddress } = state.session.value;

  // Get the URL
  const url = urls.UID_STATUS_LDAP;

  // Create the request body
  const requestBody = searchUserReq(sessionid, userid, ipAddress, true);

  // Make API call to backend to initialize the cache session
  const response = await axios.post(url, requestBody, {
    headers,
  });
  return response?.data || null;
};

export const usrParticipantPlansCacheApi = async (requestBody) => {
  const url = urls.USR_PARTICIPANT_PLANS_CACHE_URL;
  try {
    const response = await axios.post(url, requestBody, {
      headers,
    });
    return response;
  } catch (error) {
    console.log('usrParticipantPlansCacheApi error', error);
    return error;
  }
};
