// Retry functionality for lazy loading components.
// Accepts import() function for dynamically importing components,
// and will recursively call itself until the import is successful. Default is max 5 attempts
const LazyLoadRetry = (lazyImportFn, attemptsLeft = 5) => {
  return new Promise((resolve, reject) => {
    lazyImportFn()
      .then(resolve)
      .catch((error) => {
        console.log('LazyLoader error: ' + error);
        // let us retry after 1000 ms
        setTimeout(() => {
          if (attemptsLeft === 1) {
            reject(error);
            return;
          }
          LazyLoadRetry(lazyImportFn, attemptsLeft - 1).then(resolve, reject);
        }, 1000);
      });
  });
};

export default LazyLoadRetry;
