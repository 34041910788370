import React from 'react';
import { Grid } from '@mui/material';
import SaleshubDisclosure from './SaleshubDisclosure';
import BermudaDisclosure from './BermudaDisclosure';
import DefaultDisclosure from './DefaultDisclosure';
import VitalityDisclosure from './VitalityDisclosure';
import RpsDisclosure from './RpsDisclosure';
import useAppids from 'hooks/useAppids';
import DTCPIDisclosure from './DTCPIDisclosure';

const Disclosure = () => {
  const { isRpsAppid, isSalesHubAppid, isBermudaAppid, isVitalityAppid, isDTCPIAppid} =
    useAppids();

  const styles = generateStyles(isRpsAppid, isDTCPIAppid);

  let d = new Date();
  const year = d.getFullYear();

  const getDisclosure = () => {
    if (isSalesHubAppid)
      return <SaleshubDisclosure styles={styles} year={year} />;
    if (isBermudaAppid)
      return <BermudaDisclosure styles={styles} year={year} />;
    if (isVitalityAppid) return <VitalityDisclosure styles={styles} />;
    if (isRpsAppid) return <RpsDisclosure styles={styles} />;
    if (isDTCPIAppid) return <DTCPIDisclosure />;

    return <DefaultDisclosure styles={styles} year={year} />;
  };

  return <Grid sx={styles.disclosure}>{getDisclosure()}</Grid>;
};

const generateStyles = (isRpsAppid, isDTCPIAppid) => ({
  disclosure: {
    width: {
      xs: '100%',
      sm: isRpsAppid || isDTCPIAppid ? '100%' : '50%',
    },
    padding: '40px',
    display: 'flex',
    flexDirection: 'column',
    gap: '10px',
  },
  text: {
    color: '#D4D5D8',
    fontSize: '0.8rem',
    textAlign: 'left',
    // fontFamily: typography.fontFamily,
    fontWeight: 200,
  },
});

export default Disclosure;
