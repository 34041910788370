import axios from 'axios';
import { urls } from 'constants';
import { finalizing } from './finalize';

export const endSession = (session) => {
  return new Promise((resolve, reject) => {
    // If the user is being redirected at the end of the CSL flow, skip the call to /endsession
    if (finalizing) return resolve();

    // Get the URL
    const url = urls.END_SESSION;

    // Make API call to backend to delete the cache session
    axios
      .post(
        url,
        { session },
        {
          headers: {
            'Content-Type': 'application/json',
          },
        },
      )
      .then(() => {
        resolve();
      })
      .catch(() => {
        reject();
      });
  });
};
