import * as React from 'react';
import { Typography } from '@mui/material';

export const SimpleDescription = ({ children, sx }) => {
  return <Typography sx={{ ...styles, ...sx }}>{children}</Typography>;
};

const styles = {
  fontStyle: 'normal',
  fontWeight: 400,
  fontSize: '16px',
  lineHeight: '26px',
  marginBottom: '6px',
  color: '#282B3E',
};
