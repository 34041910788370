import React, { useEffect, useState } from 'react';
import { Home1 } from '@manulife/mux-cds-icons';
import { Box } from '@mui/material';
import { colors, useTheme } from '@manulife/mux';
import { useSelector } from 'react-redux';

import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import { MiniDescription } from '../UI/Heading/MiniDescription';
import useAppids from 'hooks/useAppids';
import Utility from 'utils/Utility';

export const SideDrawer = () => {
  const theme = useTheme();
  const styles = setStyles(theme);
  const { homeUrl, defaultURL, logoutURL } = useSelector(selectAppSettings);
  const { isRpsAppid, isVitalityAppid } = useAppids();
  const [homeMenuUrl, setHomeMenuUrl] = useState('');

  // const navUrl = isRpsAppid ? homeUrl : defaultURL;

  useEffect(() => {
    let redirectUrl;
    if (
      (Utility.isVitalityDomain() || isVitalityAppid) &&
      window.location.pathname === '/auth/intermediatelogin'
    ) {
      // in case of vitality decision page thne we have to logout user and back to login page
      redirectUrl = logoutURL;
    } else if (isRpsAppid) {
      redirectUrl = homeUrl;
    } else {
      redirectUrl = defaultURL;
    }

    setHomeMenuUrl(redirectUrl);
  }, [homeUrl, logoutURL, defaultURL, isVitalityAppid, isRpsAppid]);

  const sideDrawerButtons = [
    { label: 'Home', navUrl: homeMenuUrl, icon: Home1 },
    // Add remaining buttons as below
  ];

  const onClickHandler = (link) => {
    if (!link) return;
    window.location.href = link;
  };

  return (
    <Box sx={styles.container}>
      {sideDrawerButtons.map((item) => {
        const Icon = item.icon;
        return (
          <Box
            sx={styles.homeButton}
            key={item.label}
            onClick={() => onClickHandler(item.navUrl)}
          >
            <Icon style={styles.icon} />
            <MiniDescription>{item.label}</MiniDescription>
          </Box>
        );
      })}
    </Box>
  );
};

const setStyles = (theme) => {
  return {
    container: {
      display: {
        xs: 'none',
        sm: 'block',
      },
      background: theme.sideNav.mainNav.backgroundColor,
      width: '80px',
      minHeight: '100%',
    },
    linkItem: {
      textDecoration: 'none',
    },
    homeButton: {
      display: 'flex',
      flexDirection: 'column',
      alignItems: 'center',
      justifyContent: 'center',
      gap: '0.5rem',
      width: '80px',
      height: '80px',
      borderBottom: '#585B6E 2px solid',
      boxSizing: 'border-box',
      cursor: 'pointer',
      '&:hover': {
        backgroundColor: '#282B3E',
      },
    },
    icon: {
      height: '20px',
      width: '20px',
      color: colors.white,
    },
  };
};
