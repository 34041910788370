let timeoutTimer;
const timeoutDuration = 15 * 60 * 1000;

const startTimer = () => {
  if (timeoutTimer) {
    clearTimeout(timeoutTimer);
  }
  timeoutTimer = setTimeout(() => {
    window.location.reload();
  }, timeoutDuration);
};

export default startTimer;
