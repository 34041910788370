import { createSlice } from '@reduxjs/toolkit';
import {
  navigateToPassword,
  onSubmitNonPotAction,
  onSubmitPassword,
  onSubmitPotAction,
  onSubmitSprntAction,
  usernameEnteredAction,
  usernameEnteredActionRps,
} from './login.action';
// import { authtypes } from '../../../constants';
import { inputObj, signInSteps } from './login.helper';
import { apiVariable } from 'constants/common';
import { DEFAULT_ERROR } from 'application/enum';

const initialState = {
  dlfwd: null,
  signInStep: signInSteps.username, // 0
  migrationDetails: {},
  uidValidationLDAP: null,
  username: { ...inputObj },
  password: { ...inputObj },
  anotherway: {
    loading: false,
    error: null,
  },
  nonPotSubmit: { ...apiVariable },
  potSubmit: { ...apiVariable },
  sprntSubmit: { ...apiVariable },
};

export const loginSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    onChange: (state, action) => {
      const obj = action.payload;
      const dat = Object.keys(obj);
      dat.forEach((key) => {
        state[key].value = obj[key];
      });
    },
    setLoading: (state, action) => {
      const obj = action.payload;
      const dat = Object.keys(obj);
      dat.forEach((key) => {
        state[key].loading = obj[key];
      });
    },
    setError: (state, action) => {
      const obj = action.payload;
      const dat = Object.keys(obj);
      dat.forEach((key) => {
        state[key].error = obj[key];
      });
    },
    goToStepUsername: (state, action) => {
      state.signInStep = signInSteps.username;
    },
    setDlfwdValue: (state, action) => {
      state.dlfwd = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(usernameEnteredActionRps.pending, (state, action) => {
        state.migrationDetails.apiStatus = 'pending'; // this is not for loader, but for next api call in password
        state.signInStep = signInSteps.password; // gets navigated to password page
      })
      .addCase(usernameEnteredActionRps.fulfilled, (state, action) => {
        const migrationRes = action.payload;
        state.migrationDetails = { ...migrationRes, apiStatus: 'fulfilled' };
        state.username.loading = false;
        state.username.error = null;
        // state.signInStep = signInSteps.password; // gets navigated to password page
      })
      .addCase(usernameEnteredActionRps.rejected, (state, action) => {
        state.username.loading = false;
        // state.username.error = action.error?.message || DEFAULT_ERROR;
        // state.signInStep = signInSteps.username; // hiding password field if there is an error
        state.migrationDetails.apiStatus = 'rejected';
      });

    builder
      .addCase(usernameEnteredAction.pending, (state) => {
        state.username.loading = true;
      })
      .addCase(usernameEnteredAction.fulfilled, (state, action) => {
        state.username.loading = false;
        state.username.error = null;
        const { uidValRes } = action.payload;

        state.uidValidationLDAP = uidValRes;
        state.signInStep = signInSteps.password; // gets navigated to password page
      })
      .addCase(usernameEnteredAction.rejected, (state, action) => {
        state.username.loading = false;
        state.username.error = DEFAULT_ERROR; // if any of serchApi or uidLDAPValidation fails then will show this one message to user only
      });

    builder
      .addCase(onSubmitNonPotAction.pending, (state) => {
        state.nonPotSubmit.loading = true;
      })
      .addCase(onSubmitNonPotAction.fulfilled, (state, action) => {
        state.nonPotSubmit.loading = false;
        state.nonPotSubmit.data = action.payload;
        state.nonPotSubmit.error = null;
        state.password.error = null;
      })
      .addCase(onSubmitNonPotAction.rejected, (state, action) => {
        state.nonPotSubmit.loading = false;
        state.password.loading = false;
        const errorMessage = action.error?.message || DEFAULT_ERROR;
        state.nonPotSubmit.error = errorMessage;
        state.password.error = DEFAULT_ERROR;
      });

    builder
      .addCase(onSubmitSprntAction.pending, (state) => {
        state.sprntSubmit.loading = true;
      })
      .addCase(onSubmitSprntAction.fulfilled, (state, action) => {
        state.sprntSubmit.loading = false;
        state.sprntSubmit.data = action.payload;
        state.sprntSubmit.error = null;
        state.password.error = null;
        state.password.loading = false;
      })
      .addCase(onSubmitSprntAction.rejected, (state, action) => {
        state.sprntSubmit.loading = false;
        state.password.loading = false;
        const errorMessage = action.error?.message || DEFAULT_ERROR;
        state.sprntSubmit.error = errorMessage;
        state.password.error = errorMessage;
      });

    builder
      .addCase(onSubmitPotAction.pending, (state) => {
        state.password.loading = true;
      })
      .addCase(onSubmitPotAction.fulfilled, (state, action) => {
        state.password.loading = false;
        state.potSubmit.data = action.payload;
        state.password.error = null;
      })
      .addCase(onSubmitPotAction.rejected, (state, action) => {
        state.password.loading = false;
        state.password.error = action.error?.message || DEFAULT_ERROR;
      });

    builder
      .addCase(onSubmitPassword.pending, (state) => {
        state.password.loading = true;
      })
      .addCase(onSubmitPassword.fulfilled, (state, action) => {
        state.password.loading = false;
        state.password.error = null;
        if (action.payload) {
          state.username = {
            ...state.username,
            error: action.payload.details || DEFAULT_ERROR,
          };
          state.password.value = '';
          state.signInStep = signInSteps.username;
        }
      })
      .addCase(onSubmitPassword.rejected, (state, action) => {
        state.password.loading = false;
        state.password.error = action.error?.message || DEFAULT_ERROR;
      });

    builder.addCase(navigateToPassword.fulfilled, (state, action) => {
      state.signInStep = signInSteps.password;
    });
  },
});

export const {
  onChange,
  setLoading,
  goToStepUsername,
  setDlfwdValue,
  setError,
} = loginSlice.actions;
export const loginSelector = (state) => state.login;
export default loginSlice.reducer;
