import { createSlice } from '@reduxjs/toolkit';
import { apiVariable } from '../../../constants/common';
import { logoutCIAMAction } from './globals.action';
import { DEFAULT_ERROR } from 'application/enum';

const initialState = {
  globalLoading: false,
  logout: { ...apiVariable },
};

export const globalsSlice = createSlice({
  name: 'globals',
  initialState,
  reducers: {
    setGlobalLoader: (state, action) => {
      state.globalLoading = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(logoutCIAMAction.pending, (state) => {
        state.logout.loading = true;
      })
      .addCase(logoutCIAMAction.fulfilled, (state, action) => {
        state.logout.loading = false;
        state.logout.data = action.payload;
        state.logout.error = null;
      })
      .addCase(logoutCIAMAction.rejected, (state, action) => {
        state.logout.loading = false;
        state.logout.error = action.error?.message || DEFAULT_ERROR;
      });
  },
});

export const { setGlobalLoader } = globalsSlice.actions;
export const globalsSelector = (state) => state.globals;
export default globalsSlice.reducer;
