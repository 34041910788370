import {
  adobeActiveApps,
  adobePageNames,
  adobeUrls,
  pageSubSectionName,
} from 'application/configurations/adobe.config';
import { hashSHA256 } from 'utils/commonLogics';
import { generateAppid } from './useAppids';
import { isLoginStartEventExists } from './hooks.helper';

const useAdobeDataLayer = () => {
  //   For Reference
  //   window.location.href: 'http://localhost:3000/auth/login?appid=362772';
  //   window.location.origin: 'http://localhost:3000';
  //   window.location.pathname: '/auth/login';
  const pageURL = window.location.href;

  const siteSection = 'login';
  const errorCategory = 'login-in-error';
  const siteType = 'CWS';

  const events = {
    loginStart: 'loginStart',
    loginComplete: 'loginComplete',
    pageLoad: 'pageLoad',
    loginError: 'loginError',
    linkClick: 'linkClick',
  };

  const generatePageName = (subPage = '') => {
    const { appid } = generateAppid();
    const pageNameSection1 = adobePageNames[appid] || '';

    const pageNameSection2 = pageSubSectionName[appid][subPage] || '';
    return pageNameSection1 + pageNameSection2;
  };

  const pushHandler = (eventObject) => {
    const { appid } = generateAppid();
    const isActiveByApp = adobeActiveApps[appid];
    const isActiveByEnv = process.env.REACT_APP_DATA_LAYER === 'enabled';

    // isActiveByEnv: feature can be turned off according to regions
    // isActiveByApp: feature will be turned according to appid
    if (!isActiveByEnv || !isActiveByApp) return;

    const analyticLayer = window.adobeDataLayer;
    if (!analyticLayer) window.adobeDataLayer = [];

    console.log('datalayer event:', eventObject.event);

    window.adobeDataLayer.push(eventObject);
  };

  const addAdobeScript = (appid) => {
    const isActiveByApp = adobeActiveApps[appid];
    const isActiveByEnv = process.env.REACT_APP_DATA_LAYER === 'enabled';

    if (isActiveByEnv && isActiveByApp) {
      console.log('Loading Datalayer Script...');
      const dlScript = document.createElement('script');
      dlScript.src = adobeUrls[appid];
      dlScript.async = true;
      window.document.head.appendChild(dlScript);
    }
  };

  const loginStartDL = () => {
    const isLoginEventExists = isLoginStartEventExists();
    if (isLoginEventExists) return;

    const pageName = generatePageName('login');

    pushHandler({
      event: events.loginStart,
      page: {
        pageName,
        siteSection,
        pageURL,
        siteType,
      },
    });
  };

  /* userID/userName: shall be hashed  with SHA256 encrytion */
  const loginSuccessfulDL = async (userName, subPage) => {
    const hashedUsername = await hashSHA256(userName);
    const pageName = generatePageName(subPage);

    pushHandler({
      event: events.loginComplete,
      page: {
        pageName,
        siteSection,
        pageURL,
        siteType,
      },
      user: {
        userID: hashedUsername,
      },
    });
  };

  // 'siteSection': 'login', // hardcode the section of the website
  const pageLoadsDL = (subPage) => {
    const pageName = generatePageName(subPage);

    pushHandler({
      event: events.pageLoad,
      page: {
        pageName,
        siteSection,
        pageURL,
        siteType,
      },
    });
  };

  // 'errorType':  <errorType>, // Capture 'source:type' E.g  'API:Akamai Failure', UX: Invalid Selections, userError:three-failed-attempts, wrong-password/user name
  // 'errorCode': <errorCode>,   // Error code in the backend / transaction systems E.g 502, 404, 500
  // 'errorCategory': <errorCategory>, 'login-in-error'
  // 'errorDescription': <errorDescription>, //Actual Error Text

  const onError = ({ errorType, errorCode, errorDescription }) => {
    const pageName = generatePageName();

    pushHandler({
      event: events.loginError,
      error: {
        errorType,
        errorCode,
        errorCategory,
        errorDescription,
      },
      page: {
        pageName,
        siteSection,
        pageURL,
        siteType,
      },
    });
  };

  const onClickLink = (linkName, linkHref) => {
    const pageName = generatePageName();

    pushHandler({
      event: events.linkClick,
      page: {
        pageName,
        siteSection, //hardcode the section of the website
        pageURL,
        siteType,
      },
      link: {
        linkName,
        linkHref,
      },
    });
  };

  return {
    addAdobeScript,
    loginStartDL,
    loginSuccessfulDL,
    pageLoadsDL,
    onError,
    onClickLink,
  };
};

export default useAdobeDataLayer;
