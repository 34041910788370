export const FOR_MGROUP_ACCOUNTS = 'For MGroup accounts';
export const FOR_EDWARD_JONES_ACCOUNTS = 'For Edward Jones accounts';

export const ACCES_DENIED = 'Access Denied';
export const CONTACT_US = 'Contact us';
export const PLEASE_WAIT = 'Please wait...';

export const ADDITIONAL_INFORMATION = 'Additional Information';
export const VERIFY_IDENTITY = 'Verify your identity';

export const DEFAULT_TAB_TITLE = 'Sign in';
export const VITALITY_TAB_TITLE = 'John Hancock Vitality';
export const COMMON_SIGNIN_HEADING = 'Sign in';
export const RPS_SIGNIN_HEADING = 'Sign in to your plan';
export const SQN_SSN_SIGNIN_HEADING = 'Sign in another way';

export const DEFAULT_TAB_FAV_ICON_PATH = '%PUBLIC_URL%/../favicon.ico';
export const VITALITY_TAB_FAV_ICON_PATH =
  '%PUBLIC_URL%/../vitality_favicon.ico';
export const RPS_TAB_FAV_ICON_PATH = '%PUBLIC_URL%/../rps_favicon.png';

export const DEFAULT_ERROR = 'Something went wrong, please try again';
export const PROFILE_FETCH_ERROR =
  'Something went wrong while getting user profile information';

export const YOUR_SECURITY_CODE_IS_INCORRECT =
  'Your security code is incorrect. Please try again';
