import { createApi } from '@reduxjs/toolkit/query/react';
import { fetchMaintenanceMsg } from 'api/maintenanceMsg';

/**
 * for now no use of maintenance slice, in future needed then add states and use it.
 * For now will get messages using RTK query so that state is maintain by itself.
 * Reason of using RTK query for maintenance message api is API call with `appid` take much time as compare to `domain`.
 * This can't be handled using AbortController too.
 * so this can be easily handled using RTK query.
 *
 * in future any call need to add then just add another entry in `endpoints` like `getMaintenanceMsg`. and -
 * then please move this file to store folder to make it generalize
 */

export const rtkqueryApi = createApi({
  reducerPath: 'rtkqueryApi',
  endpoints: (builder) => ({
    // consider this api as query so that response cached
    getMaintenanceMsg: builder.query({
      queryFn: ({ appid, domain }) => fetchMaintenanceMsg({ appid, domain }),
    }),
  }),
});

export const { useGetMaintenanceMsgQuery } = rtkqueryApi;
