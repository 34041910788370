import React from 'react';
import { Box, Typography } from '@mui/material';
import { messages } from 'constants/common';

const DTCPIDisclosure = ({ year }) => {
  return (
    <Box sx={styles.disclosureContainer}>
      <Box sx={styles.section}>
        <Typography sx={styles.text}>
          {messages.DTC_DISCLOSURE_MESSAGE}
        </Typography>
      </Box>
    </Box>
  );
};

const styles = {
  disclosureContainer: {
    display: 'flex',
    flexDirection: 'column',
    gap: '24px',
  },
  section: {
    display: 'flex',
    flexDirection: 'column',
    gap: '28px',
    marginRight: '24px',
  },
  boldText: {
    fontFamily: 'Manulife JH Sans',
    fontSize: '16px',
    color: '#DDDEE4',
    fontWeight: '600',
    letterSpacing: '0px',
    lineHeight: '20px',
  },
  text: {
    fontFamily: 'Manulife JH Sans',
    fontSize: '13px',
    color: '#D4D5D8',
    fontWeight: '400',
    letterSpacing: '0px',
    lineHeight: '16px',
  },
};

export default DTCPIDisclosure;
