import * as React from 'react';
import { Box, Typography } from '@mui/material';
import { Button as MUXButton } from '@manulife/mux';
import { VALIDATE_FIELDS } from 'application/enum/validations.enum';

export const Button = ({
  children,
  sx = {},
  onClick = () => {},
  variant = MUXButton.VARIANT.PRIMARY,
  disabled = false,
  loading = false,
  customErrorMessage,
}) => {
  return (
    <Box sx={{ ...styles.container, ...sx }}>
      <MUXButton
        customStyle={styles.muxButton}
        onClick={() => !disabled && !loading && onClick()}
        saving={loading}
        variant={variant}
      >
        {children}
      </MUXButton>
      {disabled && (
        <Typography sx={styles.error}>
          {customErrorMessage || VALIDATE_FIELDS}
        </Typography>
      )}
    </Box>
  );
};

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    justifyContent: 'flex-start',
  },
  muxButton: {
    buttonStyle: {
      width: '100%',
    },
  },
  error: {
    maxWidth: {
      xs: 'auto',
      sm: '150px',
    },
    display: 'flex',
    color: '#A00E18',
    marginBottom: '0px',
    marginTop: '8px',
    alignItems: 'center',
    justifyContent: 'flex-start',
    textWrap: {
      xs: 'wrap',
      sm: 'nowrap',
    },
    fontWeight: '600',
    fontSize: '13px',
    lineHeight: '16px',
    '& path': {
      color: '#A00E18',
    },
  },
};
