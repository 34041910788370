import axios from 'axios';
import { urls } from 'constants';

/**
 * fetch maintenance message for domain if exists else check appid is in centralreg2 maintenance message
 * @param {{appid: String, domain: String | null}} body
 * @returns
 */
export const fetchMaintenanceMsg = async (body) => {
  try {
    const url = urls.MAINTENANCE_MESSAGE;
    return await axios.post(url, body);
  } catch (error) {
    throw new error();
  }
};
