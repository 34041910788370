import React from 'react';
import { Box, Typography } from '@mui/material';
import { RPSdisclosuresMock } from '../footer.helper';

const RpsDisclosure = ({ styles, year }) => {
  return (
    <Box sx={styles2.container}>
      {RPSdisclosuresMock.map((el) => (
        <Typography key={el} sx={{ ...styles.text, marginBottom: '12px' }}>
          {el}
        </Typography>
      ))}
    </Box>
  );
};

const styles2 = {
  container: {},
};

export default RpsDisclosure;
