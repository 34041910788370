import React, { useEffect } from 'react';
import './App.css';
import { MuxProvider } from '@manulife/mux';
import LoadOverlay from './application/Components/Loaders/LoadOverlay';
import Routes from './Routes';
import Header from './application/Components/Header/Header';
import { SideDrawer } from './application/Components/SideDrawer/SideDrawer';
import Footer from './application/Components/Footer/Footer';
import useInitialize from 'hooks/useInitialize';
import useTheming from 'hooks/useTheming';
import { setAppId } from 'store/reducers/appSettings/appSettings.slice';
import { useDispatch, useSelector } from 'react-redux';
import { useSearchParams, useLocation, useNavigate } from 'react-router-dom';
import { MaintenanceMessage } from 'application/Components/Maintenance/MaintenanceMessage';
import { globalsSelector } from 'store/reducers/global/globals.slice';
import useAdobeDataLayer from 'hooks/useAdobeDataLayer';
import useAppids from 'hooks/useAppids';
import { CacheProvider } from '@emotion/react';
import createCache from '@emotion/cache';
import Utility from 'utils/Utility';
import { appGroups } from 'constants/brandings';
import { Helmet } from 'react-helmet';

function App() {
  const { iniFrame, initializing } = useInitialize();
  const { addAdobeScript, pageLoadsDL } = useAdobeDataLayer();
  const theme = useTheming();
  const dispatch = useDispatch();
  const [searchParams] = useSearchParams();
  const location = useLocation();
  const navigate = useNavigate();
  const appid = searchParams.get('appid');
  const { isVitalityAppid } = useAppids();

  const { globalLoading } = useSelector(globalsSelector);

  useEffect(() => {
    dispatch(setAppId(appid));
    addAdobeScript(appid);
  }, []);

  if (iniFrame()) {
    return null;
  }

  useEffect(() => {
    removeUnUsedUrlSearchParams();
  }, []);

  useEffect(() => {
    pageLoadsDL('login');
  }, []);

  function removeUnUsedUrlSearchParams() {
    let hasUpdate = false;
    const queryParams = new URLSearchParams(location.search);

    let isVitality = isVitalityAppid || Utility.isVitalityDomain();

    if (isVitality) {
      if (queryParams.get('appid') !== appGroups.VITALITY_WEB_APPID) {
        queryParams.set('appid', appGroups.VITALITY_WEB_APPID);
        hasUpdate = true;
      }

      if (queryParams.has('TAM_OP')) {
        queryParams.delete('TAM_OP');
        hasUpdate = true;
      }

      if (queryParams.has('uid')) {
        queryParams.delete('uid');
        hasUpdate = true;
      }

      if (queryParams.has('url')) {
        queryParams.delete('url');
        hasUpdate = true;
      }

      if (hasUpdate) {
        navigate(
          {
            search: queryParams.toString(),
          },
          { replace: true },
        );
        window.location.reload();
      }
    }
  }

  const cache = createCache({
    key: 'emotion',
    // nonce: window.__nonce,
    nonce: 'dynamic-nonce',
    prepend: true,
  });

  return (
    <CacheProvider value={cache}>
      <Helmet>
        <link rel="icon" href={Utility.getFavIcon(appid)} />
        <title>{Utility.getTabTitle(appid)}</title>
      </Helmet>
      {globalLoading || initializing ? (
        <LoadOverlay />
      ) : (
        <MuxProvider themeProvider={theme}>
          <div className="App">
            <SideDrawer />
            <div className="main-container">
              <Header />
              <div className="router-wrapper">
                <Routes />
              </div>
              <Footer />
              <div className="jh-webchat" id="chatBotDiv" />
            </div>
            <MaintenanceMessage />
          </div>
        </MuxProvider>
      )}
    </CacheProvider>
  );
}

export default App;
