const domain =
  process.env.REACT_APP_NODE_ENV === 'local'
    ? 'https://test.partnerlink.jhancock.com/authb'
    : window.location.protocol + '//' + window.location.hostname + '/authb';

const cusc_domain =
  process.env.REACT_APP_NODE_ENV === 'local'
    ? 'http://localhost:4000'
    : window.location.protocol + '//' + window.location.hostname;

// Uncomment if you need to test with the backend running locally
// const domain = 'http://localhost:4000';

export const urls = {
  INITIALIZE: `${domain}/initialize`,
  INITIALIZE_LOGIN: `${domain}/initialize/login`,
  INITIALIZE_SSO: `${domain}/initialize/sso`,
  INITIALIZE_MFA: `${domain}/initialize/mfa`,
  INITIALIZE_MFA_WITH_RSA: `${domain}/initialize/mfa/rsa`,
  INITIALIZE_MFA_FOR_CUSC: `${domain}/initialize/mfa/cusc`,
  USER_SEARCH: `${domain}/user/search`,
  USER_MIGRATION_STATUS: `${domain}/user/getMigrationStatus`,
  INITIALIZE_THRESHOLD: `${domain}/initialize/FE/Threshold`,

  SET_USER_MIGRATION_STATUS: `${domain}/user/setMigrationComplete`,
  UID_STATUS_LDAP: `${domain}/user/uidValidationLDAP`,
  GET_AUTH_TYPE: `${domain}/user/authtype`,
  AUTHENTICATE: `${domain}/authenticate`,
  AUTHENTICATE_ANOTHER_WAY: `${domain}/authenticate/anotherway`,
  AUTHENTICATE_AUTHISAM: `${domain}/authenticate/authIsamOAuth`,
  MFA_SEND: `${domain}/mfa/send`,
  MFA_VERIFY: `${domain}/mfa/verify`,
  REDIRECT: `${domain}/redirect`,
  REDIRECT_SSO: `${domain}/redirect/sso`,
  REDIRECT_TO_RPS: `${domain}/redirect/rps`,
  REDIRECT_TO_NONMIGRATED_MFA: `${domain}/redirect/nonmigratedmfaonly`,
  REDIRECT_TO_RESET_PASSWORD:
    `${cusc_domain}` + process.env.REACT_APP_REDIRECT_RESET_PASSWORD,

  END_SESSION: `${domain}/endsession`,
  JWT_WSJAUTH: `${domain}/jwtwsjauth`,
  MDM_SEARCH_UID: `${domain}/mdm/searchUID`,
  UPDATE_USERPROFILE: `${domain}/user/updateUserDetails`,
  ISAM_PASSWORD_RESET: `${domain}/user/updateIsamPassword`,
  USR_PARTICIPANT_PLANS_CACHE_URL: `${domain}/user/USRParticipantPlansCache`,
  CREATE_USER_WHEN_CONFLICT: `${domain}/user/createUserWhenConflict`,
  VALIDATE_CREDENTIALS_URL: `${domain}/validatePassword`,
  PHONE_VALIDATION: `${domain}/user/validatePhone`,
  INITIALIZE_LIGHTWEIGHT: `${domain}/initialize/lightweight`,
  MAINTENANCE_MESSAGE: `${domain}/maintenance/message`,
  LOGOUT_CIAM: `${domain}/authenticate/logoutCIAM`,
  CHATBOT_TOKEN: `${domain}/chatBotToken`,

  REDIRECT_TO_WSJAUTH: process.env.REACT_APP_WSJAUTH_URL,
  REDIRECT_TO_WSJAUTH_SPRNT: process.env.REACT_APP_REDIRECT_TO_WSJAUTH_SPRNT,

  LEGAL_REDIRECT: process.env.REACT_APP_LEGAL_REDIRECT_URL,
  PRIVACY_REDIRECT: process.env.REACT_APP_PRIVACY_REDIRECT_URL,
  ACCESSIBILITY_REDIRECT: process.env.REACT_APP_ACCESSIBILITY_REDIRECT_URL,
  ACCOUNTSECURITY_REDIRECT: process.env.REACT_APP_ACCOUNTSECURITY_REDIRECT_URL,
  CORPORATEWEBSITE_REDIRECT:
    process.env.REACT_APP_CORPORATEWEBSITE_REDIRECT_URL,
  CONTACT_US_REDIRECT: process.env.REACT_APP_CONTACT_US_REDIRECT_URL,
  REACT_APP_RPS_FAQ_URL: process.env.REACT_APP_RPS_FAQ_URL,
  REACT_APP_THRESHOLD: process.env.REACT_APP_THRESHOLD_URL,

  IPIFY: 'https://api.ipify.org/?format=json',
};
