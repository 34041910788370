export const codes = {
  SUCCESS: '0000',
  SUCCESS_EMAIL: '0001',
  MFA_CHALLENGE: '0008',
  DENY_ACCESS: '0011',

  URL_NOT_WHITELISTED: '4004',

  RECENTLY_USED_PASSWORD: '2318',
  INCORRECT_CREDENTIALS: '5101',
  ACCOUNT_LOCKED: '5102',

  INCORRECT_MFA_CODE: '5201',
  MFA_CODE_EXPIRED: '5202',
  MFA_RATE_LIMIT_REACHED: '5203',

  JWT_EXPIRED: '5301',

  INDETERMINANT_ERROR: '5103',
  // "Indeterminate while authenticating the user"

  PHONE_VALIDATION_FAILED: '5801',
  UNKNOWN_ERROR: '9000',
  UNVERIFIED_USER: '9056',
};
