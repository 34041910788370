export const paths = {
  BASE_PATH: '/auth',
  LOGIN: '/auth/login',
  SIGN_IN_ANOTHER_WAY: '/auth/signinAnotherWay', // shall add authType queryParam

  // mfa routes
  LOGIN_MFA: '/auth/login/mfa',
  POST_LOGIN_MFA: '/auth/postlogin/mfa',
  CUSC_MFA: '/auth/cusc/mfa',
  MFA: '/auth/mfa',
  SSO: '/auth/sso',
  SSO_MFA: '/auth/sso/mfa',
  NON_MIGRATED_MFA: '/auth/mfausr',

  PHONE_VERIFICATION: '/auth/verify',
  INTERMEDIATE_LOGIN: '/auth/intermediatelogin',

  // error routes
  DEFAULT_ERROR: '/auth/error',
  ACCESS_DENIED: '/auth/error/denied',
  ACCOUNT_LOCKED: '/auth/error/locked',
  EXPIRED_LINK: '/auth/error/expired',
  PHONE_VERIFICATION_FAIL: '/auth/error/verificationfailure',
  INDETERMINANT_ERROR: '/auth/Indeterminant-error',

  // RPS
  UPDATE_PROFILE: '/auth/update-profile',
  UPDATE_PROFILE_SUCCESS: '/auth/update-profile/success',
  NO_PHONE_NO_EMAIL: '/auth/nophone-noemail', // need to modify when we got update from HCD

  // others
  HEALTH: '/auth/health',
};
