import store from 'store';
import { urls } from 'constants';
import { paths } from 'constants';
import { appGroups } from 'constants';
import { setGlobalLoader } from 'store/reducers/global/globals.slice';
import { apiVariable } from 'constants/common';

// Flag value to ensure /endsession doesn't get called when we submit the browser POST to /finalize
export let finalizing = false;

export const finalize = (
  jwt,
  wsj_auth_value,
  isNonPOTFlow,
  operation = null,
) => {
  // Get sessionid from redux
  const state = store.getState();
  const { sessionid, isMigratedUsr } = state.session.value;
  const { appid } = state.appSettings.value;
  const { dlfwd } = state.login;

  finalizing = true;

  // Send the browser POST request
  let form = document.createElement('form');
  let sessionInput = document.createElement('input');
  let upepayload = document.createElement('input');
  let authorization = document.createElement('input');
  let src = document.createElement('input');
  let isMigrated = document.createElement('input');
  let cuscResetPasswordInput = document.createElement('input');
  let domain = document.createElement('input');

  const appIds = [
    ...appGroups.RPS_APPIDS.split(','),
    ...appGroups.SPRNT_APPIDS.split(','),
  ];
  const isRpsAppid = appGroups.RPS_APPIDS.split(',').includes(appid);

  form.method = 'POST';
  if (
    window.location.pathname === paths.SSO ||
    window.location.pathname === paths.SSO_MFA
  ) {
    form.action = urls.REDIRECT_SSO;
  } else if (appIds.includes(appid)) {
    if (isNonPOTFlow) {
      let redirectUrl = isRpsAppid
        ? urls.REDIRECT_TO_WSJAUTH
        : urls.REDIRECT_TO_WSJAUTH_SPRNT;

      if (dlfwd) redirectUrl += encodeURIComponent(`?dlfwd=${dlfwd}`);
      form.action = redirectUrl;
      upepayload.name = 'upepayload';
      upepayload.value = jwt;
      src.name = 'src';
      src.value = 'CSL';
      isMigrated.name = 'isMigrated';
      isMigrated.value = false;
      authorization.name = 'authorization';
      authorization.value = wsj_auth_value;
      form.appendChild(upepayload);
      form.appendChild(authorization);
      form.appendChild(src);
      form.appendChild(isMigrated);
    } else if (isMigratedUsr === false) {
      // After WSJ response, MFA completed and redirect to RPS
      form.action = urls.REDIRECT_TO_NONMIGRATED_MFA;
    } else {
      form.action = urls.REDIRECT_TO_RPS;
    }
  } else if (operation === apiVariable.FORGOT_PASSWORD) {
    form.action = urls.REDIRECT_TO_RESET_PASSWORD;
    cuscResetPasswordInput.name = 'token';
    cuscResetPasswordInput.value = jwt;
    domain.name = 'domain';
    domain.value = window.location.origin;
    form.appendChild(cuscResetPasswordInput);
    form.appendChild(domain);
  } else {
    form.action = urls.REDIRECT;
  }

  sessionInput.name = 'session';

  sessionInput.value = sessionid;
  form.appendChild(sessionInput);

  document.body.appendChild(form);

  store.dispatch(setGlobalLoader(true));
  form.submit();
};
