const flexStart = 'flex-start';

export const footerStyles = {
  container: {
    width: '100%',
    background: '#282B3E',
  },
  upperBox: {
    display: 'flex',
    flexDirection: 'row',
    borderBottom: '#34384B 2px solid',
    flexWrap: 'wrap',
    margin: {
      xs: "32px 16px",
      sm: "0px"
    },
    justifyContent: flexStart,
  },
  linkItem: {
    margin: '30px',
    a: {
      color: 'white',
      fontWeight: '200',
      fontSize: '14px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  contactUs: {
    display: 'flex',
    alignItems: 'center',
    gap: '8px',
    flexGrow: {
      xs: 0,
      sm: 1,
    },

    '& svg': {
      color: '#fff',
    },
  },
  lowerBox: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      sm: 'row',
    },
    justifyContent: {
      xs: 'center',
      sm: flexStart,
    },
  },
  lowerBoxDTC: {
    display: 'flex',
    flexDirection: 'column',
  },
  logo: {
    width: {
      xs: '100%',
      sm: '33.33%', // common layout grid system
    },
    display: 'flex',
    justifyContent: {
      xs: 'center',
      sm: flexStart,
    },
  },
  logoImg: {
    height: '40px',
    paddingTop: '30px',
    paddingLeft: '30px',
  },
  logoImgDTC: {
    maxHeight: "53px",
    maxWidth: "265px"
  },
  socialsContainer: {
    padding: {
      xs: '20px 32px 32px 32px',
      sm: '20px 32px 32px 32px',
      md: '32px',
      lg: '32px 32px 32px 156px',
    },
    display: 'flex',
    flexDirection: 'row',
    gap: "8px"

  },
  socialsImage: {
    width: "40px",
    height: "40px",
  },
  linkItemDTC: {
    margin: {
      xs: '30px',
      sm: '30px',
      md: '42px 30px',
    },
    a: {
      color: 'white',
      fontWeight: '200',
      fontSize: '14px',
      textDecoration: 'none',
      '&:hover': {
        textDecoration: 'underline',
      },
    },
  },
  logoCopyContainer: {
    display: 'flex',
    justifyContent: "space-between",
    alignItems: "flex-start",
    "& > *": {
      flex: "0 0 40%", // Each child takes up 50% of the container width
    },
    flexDirection: {
      xs: 'column',
      sm: 'row'
    },
    gap: {
      xs: "24px"
    },
    margin: "24px 40px 64px 40px"
  },
  copyText: {
    fontFamily: "Manulife JH Sans",
    fontSize: "13px",
    color: "#D4D5D8",
    fontWeight: "400",
    letterSpacing: "0px",
    lineHeight: "16px"
  },
};
