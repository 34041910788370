import axios from 'axios';
import {
  initializeReq,
  initializeWithJWTReq,
  initializeMfaRsaReq,
} from './model/initializeReq';
import { finalize } from './finalize';
import {
  setAppId,
  setAppSettings,
} from 'store/reducers/appSettings/appSettings.slice';
import {
  setSessionid,
  setContactInfo,
  setIPAddress,
  setMigratedUser,
} from 'store/reducers/session/session.slice';
import { codes, paths, urls } from 'constants';
import store from 'store';
import { getIP } from './ipify';
import { rsaCookie } from 'utils/storage';
import storage from 'utils/storage';
import { storageNames } from 'constants';
import { setDlfwdValue } from 'store/reducers/login/login.slice';
import { logoutCIAMAction } from 'store/reducers/global/globals.action';
import { thresholdAPI } from './threshold';
import startTimer from './apiUtils/reload';

const APPLICATION_JSON = 'application/json';

// --- Set of Arrays containing the paths which require certain initialization scenarios --- //
// Paths where no initialization is required
const NO_INITIALIZE_PATHS = [paths.HEALTH];

// Paths where the user needs to be redirected to the login page first
const LOGIN_REQUIRED_PATHS = [paths.LOGIN_MFA];

// Paths where /initialize/login needs to be called
// (the main login flow, where a backend session needs to be created)
const INITIALIZE_LOGIN_PATHS = [paths.LOGIN];

// Paths where /initialize/sso needs to be called
// (the SSO login flow, where a backend session needs to be created)
const INITIALIZE_SSO_PATHS = [paths.SSO];

// Paths where /initialize/mfa needs to be called
// (paths where the user is already in an authenticated session and the userid exists in the ISAM headers)
const INITIALIZE_MFA_PATHS = [paths.MFA, paths.CUSC_MFA];

// Paths where /initialize/mfa/rsa needs to be called
// (paths where the user has completed login in a different flow, but we need to check RSA to see if they still need to complete MFA)
const INITIALIZE_MFA_WITH_RSA_PATHS = [paths.POST_LOGIN_MFA];

// Paths where we need to get the input values from a JWT in the sessionStorage (userid, url, etc.)
const JWT_INPUT = [paths.CUSC_MFA];

// Paths where /initialize/lightweight needs to be called
// (paths where it will call the /initialize/lightweight endpoint on page load.)
const INITIALIZE_LIGHTWEIGHT_PATHS = [paths.PHONE_VERIFICATION];

// Paths where /initialize/mfa/rsa needs to be called
// (paths where the user has completed login in a different flow, but we need to check RSA to see if they still need to complete MFA)
const INITIALIZE_NON_MGRTD_MFA_WITH_RSA_PATHS = [paths.NON_MIGRATED_MFA];

// Main initialize function, will call specific initialize API calls depending on path
export const initialize = (navigate) => {
  return new Promise((resolve, reject) => {
    const currentPath = window.location.pathname;

    // If no initialization is required, return success
    if (NO_INITIALIZE_PATHS.includes(currentPath)) return resolve();

    // If login is required before accessing this page, redirect to paths.LOGIN
    if (LOGIN_REQUIRED_PATHS.includes(currentPath)) {
      navigate({
        pathname: paths.LOGIN,
        search: window.location.search,
      });
    }

    // If /initialize/login needs to be called, call the initializeLogin() method
    // The main login flow, gets the branding information and starts a backend session
    if (INITIALIZE_LOGIN_PATHS.includes(currentPath))
      return initializeLogin(resolve, reject, navigate);

    // If /initialize/sso needs to be called, call the initializeSSO() method
    // The SSO login flow, gets the branding information and starts a backend session
    if (INITIALIZE_SSO_PATHS.includes(currentPath))
      return initializeSSO(resolve, reject, navigate);

    // If /initialize/mfa needs to be called, call the initializeMfa() method
    // MFA flow where the user is already in an authenticated session and the userid exists in the ISAM headers
    // Gets the branding information and starts a backend session
    if (INITIALIZE_MFA_PATHS.includes(currentPath))
      return initializeMfa(resolve, reject, navigate);

    // If /initialize/mfa/rsa needs to be called, call the initializeMfaRsaPostAuth() method
    // MFA flow where the user has completed login in a different flow, but we need to check RSA to see if they still need to complete MFA
    // Gets the branding information and starts a backend session
    if (INITIALIZE_MFA_WITH_RSA_PATHS.includes(currentPath))
      return initializeMfaRsa(resolve, reject, navigate);

    // If /initialize/lightweight needs to be called, call the initializeLightWeight() method
    // Lightweight flow
    // Gets the branding information and starts a backend session
    if (INITIALIZE_LIGHTWEIGHT_PATHS.includes(currentPath))
      return initializeLightWeight(resolve, reject, navigate);

    // MFA flow where the user is non-migrated, we need to check RSA to see if they still need to complete MFA
    // Gets the branding information and starts a backend session
    if (INITIALIZE_NON_MGRTD_MFA_WITH_RSA_PATHS.includes(currentPath))
      return initializeNonMgrtdMfaRsa(resolve, reject, navigate);

    // If none of the above usecases have been triggered, the default /initialize endpoint needs to be called
    // Gets all branding information without starting a session.
    return initializeLogin(resolve);
  });
};

const initializeLogin = async (resolve, reject, navigate) => {
  // Get the appid and optional redirect URL from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const appid = urlParams.get('appid') || 1;
  const redirectUrl = urlParams.get('url');
  const dlfwd = urlParams.get('dlfwd');

  startTimer();
  if (appid === '1104') {
    const thresholdResponse = await thresholdAPI(appid);
    if (thresholdResponse === true) {
      const thresholdURL = urls.REACT_APP_THRESHOLD;
      return (window.location.href = thresholdURL);
    }
  }

  getIP().then((ip) => {
    store.dispatch(setIPAddress(ip));
  });

  store.dispatch(setAppId(appid));

  if (dlfwd) {
    store.dispatch(setDlfwdValue(dlfwd));
  }

  const url = urls.INITIALIZE_LOGIN;

  // Make API call to backend to initialize the cache session
  axios
    .post(url, initializeReq(appid, redirectUrl, dlfwd), {
      headers: {
        'Content-Type': APPLICATION_JSON,
      },
    })
    .then((response) => {
      // If the session was created successfully, update the redux store and return
      if (response.data.details?.session) {
        store.dispatch(setSessionid(response.data.details.session));
        store.dispatch(setAppSettings(response.data.details.appsettings));

        return resolve(response.data.details.session);
      }

      // If the session wasn't created, display the default error page
      navigate(paths.DEFAULT_ERROR);

      return resolve();
    })
    .catch((error) => {
      const queryparams = new URLSearchParams(window.location.search);
      // If the url provided in the query params was not whitelisted, reload with the default redirect url
      if (
        error.response?.data?.code === codes.URL_NOT_WHITELISTED &&
        queryparams.has('url')
      ) {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?appid=' +
          queryparams.get('appid');
      } else {
        // Display the default error page
        navigate(paths.DEFAULT_ERROR);
      }

      return reject();
    });
};

const initializeSSO = (resolve, reject, navigate) => {
  // Get the appid and optional redirect URL from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const appid = urlParams.get('appid') || 1;
  const redirectUrl = urlParams.get('url');

  store.dispatch(setAppId(appid));

  const url = urls.INITIALIZE_SSO;

  // Make API call to backend to initialize the cache session
  axios
    .post(url, initializeReq(appid, redirectUrl), {
      headers: {
        'Content-Type': APPLICATION_JSON,
      },
    })
    .then((response) => {
      // If the session was created successfully, update the redux store and return
      if (response.data.details?.session) {
        store.dispatch(setSessionid(response.data.details.session));
        store.dispatch(setAppSettings(response.data.details.appsettings));

        return resolve(response.data.details.session);
      }

      // If the session wasn't created, display the default error page
      navigate(paths.DEFAULT_ERROR);

      return resolve();
    })
    .catch((error) => {
      const queryparams = new URLSearchParams(window.location.search);
      // If the url provided in the query params was not whitelisted, reload with the default redirect url
      if (
        error.response?.data?.code === codes.URL_NOT_WHITELISTED &&
        queryparams.has('url')
      ) {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?appid=' +
          queryparams.get('appid');
      } else {
        // Display the default error page
        navigate(paths.DEFAULT_ERROR);
      }

      return reject();
    });
};

const initializeMfa = (resolve, reject, navigate) => {
  // Get the appid and optional redirect URL from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const appid = urlParams.get('appid') || 1;
  const redirectUrl = urlParams.get('url');
  const backUrl = urlParams.get('back');

  store.dispatch(setAppId(appid));

  let url = urls.INITIALIZE_MFA;
  let payload = initializeReq(appid, redirectUrl);

  // If the path we're on requires a JWT for initialization, update the URL/payload
  const currentPath = window.location.pathname;

  if (JWT_INPUT.includes(currentPath)) {
    url = urls.INITIALIZE_MFA_FOR_CUSC;

    // Get JWT token from sessionStorage
    let token = storage.cookie.get(storageNames.CUSC_TOKEN, { path: '/' });
    if (!token) {
      token = storage.cookie.get(storageNames.CUSC_TOKEN_PLAIN, {
        path: '/authb',
      });
    }

    payload = initializeWithJWTReq(appid, token);
  }

  // Make API call to backend to initialize the cache session
  axios
    .post(url, payload, {
      headers: {
        'Content-Type': APPLICATION_JSON,
      },
    })
    .then((response) => {
      // If the session was created successfully, update the redux store and return
      if (response.data.details?.session) {
        if (backUrl) response.data.details.appsettings.backURL = backUrl;

        store.dispatch(setSessionid(response.data.details.session));
        store.dispatch(setAppSettings(response.data.details.appsettings));
        store.dispatch(
          setContactInfo({
            email: response.data.details.email,
            mobile: response.data.details.mobile,
          }),
        );

        return resolve(response.data.details.session);
      }

      // If the session wasn't created, display the default error page
      navigate(paths.DEFAULT_ERROR);

      return resolve();
    })
    .catch((error) => {
      const queryparams = new URLSearchParams(window.location.search);
      // If the url provided in the query params was not whitelisted, reload with the default redirect url
      if (
        error.response?.data?.code === codes.URL_NOT_WHITELISTED &&
        queryparams.has('url')
      ) {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?appid=' +
          queryparams.get('appid');
      } else {
        // Display the default error page
        navigate(paths.DEFAULT_ERROR);
      }

      return reject();
    });
};

const initializeNonMgrtdMfaRsa = async (resolve, reject, navigate) => {
  store.dispatch(setMigratedUser(false));
  return initializeMfaRsa(resolve, reject, navigate); // isMigratedUsr=false; non-migrated user
};

const initializeMfaRsa = async (resolve, reject, navigate) => {
  // Get the appid and optional redirect URL from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const appid = urlParams.get('appid') || 1;
  const redirectUrl = urlParams.get('url');
  const backUrl = urlParams.get('back');

  store.dispatch(setAppId(appid));

  const url = urls.INITIALIZE_MFA_WITH_RSA;

  const ipAddress = await getIP();

  // Make API call to backend to initialize the cache session
  axios
    .post(url, initializeMfaRsaReq(appid, redirectUrl, ipAddress), {
      headers: {
        'Content-Type': APPLICATION_JSON,
      },
    })
    .then((response) => {
      const emailRes = response.data.details.email || '';
      const mobileRes = response.data.details.mobile || '';

      // If RSA returned deny access, navigate to access denied page
      if (response.code === codes.DENY_ACCESS) {
        navigate(paths.ACCESS_DENIED);
      }

      // If the session was created successfully, update the redux store and return
      if (response.data.details?.session) {
        if (backUrl) response.data.details.appsettings.backUrl = backUrl;

        store.dispatch(setSessionid(response.data.details.session));
        store.dispatch(setAppSettings(response.data.details.appsettings));

        // Update contact information if it was returned
        if (emailRes || mobileRes) {
          const mfaPayload = {
            email: emailRes,
            mobile: mobileRes,
          };
          store.dispatch(setContactInfo(mfaPayload));
        }

        // if there is no user details then call logout api (CIAM)
        if (!emailRes && !mobileRes) {
          store.dispatch(logoutCIAMAction({ appid }));
        }

        // Update RSA cookie if it was returned
        if (response.data.details.rsaCookie)
          rsaCookie.set(response.data.details.rsaCookie);

        // If RSA returned allow access, call api/finalize method to forward the user to the redirect url
        if (response.data.code === codes.SUCCESS) {
          //call api/finalize method
          finalize();
        } else {
          return resolve(response.data.details.session);
        }
      } else {
        // If the session wasn't created, display the default error page
        navigate(paths.DEFAULT_ERROR);

        return resolve();
      }
    })
    .catch((error) => {
      const queryparams = new URLSearchParams(window.location.search);
      // If the url provided in the query params was not whitelisted, reload with the default redirect url
      if (
        error.response?.data?.code === codes.URL_NOT_WHITELISTED &&
        queryparams.has('url')
      ) {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?appid=' +
          queryparams.get('appid');
      } else {
        // Display the default error page
        navigate(paths.DEFAULT_ERROR);
      }

      return reject();
    });
};

const initializeLightWeight = (resolve, reject, navigate) => {
  // Get the appid and optional redirect URL from the query params
  const urlParams = new URLSearchParams(window.location.search);
  const appid = urlParams.get('appid') || 1;
  const redirectUrl = urlParams.get('url');

  store.dispatch(setAppId(appid));

  let url = urls.INITIALIZE_LIGHTWEIGHT_PATHS;
  let payload = initializeReq(appid, redirectUrl);

  // Get JWT token from sessionStorage
  let token = storage.cookie.get(storageNames.CSL_TOKEN, { path: '/' });
  if (!token) {
    token = storage.cookie.get(storageNames.CSL_TOKEN_PLAIN, {
      path: '/authb',
    });
  }

  payload = initializeWithJWTReq(appid, token);

  // Make API call to backend to initialize the cache session
  axios
    .post(url, payload, {
      headers: {
        'Content-Type': APPLICATION_JSON,
      },
    })
    .then((response) => {
      // If the session was created successfully, update the redux store and return
      if (response.data.details?.session) {
        store.dispatch(setSessionid(response.data.details.session));
        store.dispatch(setAppSettings(response.data.details.appsettings));

        return resolve(response.data.details.session);
      }

      // If the session wasn't created, display the default error page
      navigate(paths.DEFAULT_ERROR);

      return resolve();
    })
    .catch((error) => {
      const queryparams = new URLSearchParams(window.location.search);
      // If the url provided in the query params was not whitelisted, reload with the default redirect url
      if (
        error.response?.data?.code === codes.URL_NOT_WHITELISTED &&
        queryparams.has('url')
      ) {
        window.location.href =
          window.location.origin +
          window.location.pathname +
          '?appid=' +
          queryparams.get('appid');
      } else if (error.response?.data?.code === codes.JWT_EXPIRED) {
        navigate(paths.EXPIRED_LINK);
      } else {
        // Display the default error page
        navigate(paths.DEFAULT_ERROR);
      }

      return reject();
    });
};

// const initializeDefault = (resolve) => {
//   // Get the appid and optional redirect URL from the query params
//   const urlParams = new URLSearchParams(window.location.search);
//   const appid = urlParams.get('appid') || 1;

//   store.dispatch(setAppId(appid));

//   const url = urls.INITIALIZE;

//   // Make API call to backend to initialize the cache session
//   axios
//     .post(url, initializeReq(appid), {
//       headers: {
//         'Content-Type': APPLICATION_JSON,
//       },
//     })
//     .then((response) => {
//       // If the appsettings were returned, update the redux store and return
//       if (response.data.details?.appsettings)
//         store.dispatch(setAppSettings(response.data.details.appsettings));

//       return resolve();
//     })
//     .catch(() => {
//       return resolve();
//     });
// };
