import { useSelector } from 'react-redux';
import { THEMES } from '@manulife/mux';
import { useMemo } from 'react';

import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';

const useTheming = () => {
  const { branding } = useSelector(selectAppSettings);

  const theme = useMemo(() => {
    return {
      theme:
        branding === 'MLB'
          ? THEMES.globalTheme
          : branding === 'MF'
            ? THEMES.canadianTheme
            : THEMES.johnhancockTheme,
    };
  }, [branding, THEMES]);

  return theme;
};

export default useTheming;
