import { useEffect, useState } from 'react';
import { selectSession } from 'store/reducers/session/session.slice';
import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import { initialize } from '../api/initialize';
import { endSession } from 'api/endsession';
import { useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import {
  initJHChatBotBundle,
  loadChatBot,
  unloadChatBot,
} from '../utils/scripts/ChatBotScript';

const useInitialize = () => {
  // Fix for "Clickjacking" Pen Test vulnerability
  function iniFrame() {
    return window.self !== window.top;
  }

  // Prepare navigation
  const navigate = useNavigate();

  // Get Redux values
  const { sessionid } = useSelector(selectSession);
  const { chatbotLOB, chatbotAppName } = useSelector(selectAppSettings);

  // Prepare state to toggle loading screen
  const [initializing, setInitializing] = useState(false);

  useEffect(() => {
    // If the app is being loaded in a new browser window (back-end session hasn't been created yet),
    // initialize the session
    if (!sessionid && !initializing) {
      setInitializing(true);

      initialize(navigate)
        .then((session) => {
          setInitializing(false);

          if (session) {
            window.addEventListener('beforeunload', function (e) {
              endSession(session);
            });
          }
        })
        .catch(() => {
          setInitializing(false);
        });
    }

    // Initialize the chatbot if the current appid has the chatbot appsettings
    if (chatbotLOB && chatbotAppName && window.isDOMContentLoaded) {
      initJHChatBotBundle(() => {
        let data = {
          lob: chatbotLOB,
          channelData: chatbotAppName,
        };
        loadChatBot(data);
      });
    }

    // clean up
    return () => {
      unloadChatBot();
    };
  }, [chatbotLOB, window.isDOMContentLoaded]);

  return { iniFrame, initializing };
};

export default useInitialize;
