import axios from 'axios';
import { searchUserReq } from './model/searchUserReq';
import { codes, urls } from 'constants';
import store from 'store';
import { authenticateReq } from './model/authenticateReq';
import { DEFAULT_ERROR } from 'application/enum';

const applicationJson = 'application/json';
const headers = {
  'Content-Type': applicationJson,
};

export const searchUser = (userid, searchOnly = false) => {
  return new Promise((resolve, reject) => {
    // Get sessionid  from redux
    const { sessionid, ipAddress } = store.getState().session.value;

    // Get the URL
    const url = urls.USER_SEARCH;

    // Create the request body
    const requestBody = searchUserReq(sessionid, userid, ipAddress, searchOnly);

    // Make API call to backend to initialize the cache session
    axios
      .post(url, requestBody, {
        headers,
      })
      .then((response) => {
        if (response.data?.code === codes.SUCCESS) {
          return resolve(response.data?.details);
        }

        reject(response.data.code);
      })
      .catch((error) => {
        if (error.response?.data?.code === codes.JWT_EXPIRED)
          window.location.reload();

        if (error.response?.data.code === codes.INCORRECT_CREDENTIALS)
          return reject('This John Hancock username does not exist');

        return reject(DEFAULT_ERROR);
      });
  });
};

export const getUserMigrationStatus = async (userid) => {
  try {
    // Get sessionid  from redux
    const { sessionid, ipAddress } = store.getState().session.value;

    // Get the URL
    const url = urls.USER_MIGRATION_STATUS;

    // Create the request body
    const requestBody = searchUserReq(sessionid, userid, ipAddress, true);

    // Make API call to backend to initialize the cache session

    const response = await axios.post(url, requestBody, {
      headers,
    });
    return response?.data || null;
  } catch (error) {
    const errorRes = error?.response?.data || {};
    if (errorRes.data.code === codes.JWT_EXPIRED) window.location.reload();
    return error;
  }
};

export const getNonPOTJwt = async (sessionid, password) => {
  try {
    // Get the URL
    const url = urls.JWT_WSJAUTH;

    // Create the request body
    const requestBody = authenticateReq(sessionid, password);

    return axios.post(url, requestBody, {
      headers,
    });
    // return response?.data || null;
  } catch (error) {
    const errorRes = error?.response?.data || {};
    if (errorRes.data.code === codes.JWT_EXPIRED) window.location.reload();
    return error;
  }
};

export const setMigrationComplete = async (userid) => {
  // Get sessionid  from redux
  const { sessionid, ipAddress } = store.getState().session.value;

  // Get the URL
  const url = urls.SET_USER_MIGRATION_STATUS;

  const requestBody = { session: sessionid, userid, ipAddress };

  // Make API call to backend to initialize the cache session
  const response = await axios.post(url, requestBody, {
    headers,
  });
  return response?.data || null;
};

export const uidValidationLDAP = async (userid) => {
  // Get sessionid  from redux
  const { sessionid, ipAddress } = store.getState().session.value;

  // Get the URL
  const url = urls.UID_STATUS_LDAP;

  // Create the request body
  const requestBody = searchUserReq(sessionid, userid, ipAddress, true);

  // Make API call to backend to initialize the cache session
  const response = await axios.post(url, requestBody, {
    headers,
  });
  return response?.data || null;
};
