import * as React from 'react';
import { Typography } from '@mui/material';

export const MainHeading = ({ children, sx }) => {
  return (
    <Typography variant="h1" gutterBottom sx={{ ...style, ...sx }}>
      {children}
    </Typography>
  );
};

const style = {
  fontSize: {
    xs: '2.25rem',
    sm: '3rem',
  },
  margin: 0,
  lineHeight: '60px',
  letterSpacing: '0em',
  fontWeight: 600,
};
