import axios from 'axios';
import {
  authenticateReq,
  authenticateAnotherWayReq,
} from './model/authenticateReq';
import {
  setContactInfo,
  setPassword,
} from 'store/reducers/session/session.slice';
import { codes } from '../constants/codes';
import { urls } from '../constants/urls';
import { rsaCookie } from 'utils/storage';
import store from 'store';
import { DEFAULT_ERROR } from 'application/enum';

const headers = {
  'Content-Type': 'application/json',
};

const manualError = { code: codes.UNKNOWN_ERROR, messgae: DEFAULT_ERROR };

export const authenticate = (password, adobeDataLayer, username, navFlag) => {
  return new Promise((resolve, reject) => {
    // Get sessionid from redux
    const { sessionid } = store.getState().session.value;

    // Get the URL
    const url = urls.AUTHENTICATE;

    // Create the request body
    const requestBody = authenticateReq(sessionid, password, username);

    // Make API call to backend to initialize the cache session
    axios
      .post(url, requestBody, {
        headers,
      })
      .then((response) => {
        if (response.data.details?.rsaCookie)
          rsaCookie.set(response.data.details.rsaCookie);

        if (response.data.details?.email || response.data.details?.mobile)
          store.dispatch(
            setContactInfo({
              email: response.data.details.email,
              mobile: response.data.details.mobile,
            }),
          );

        if (!navFlag) {
          store.dispatch(setPassword(password));
        }

        if (response.data.code === codes.SUCCESS) {
          return resolve(response.data);
        }

        reject(response.data);
      })
      .catch((error) => {
        const errorRes = error?.response?.data || {};
        // const errorEventObj = generateAdobeEventObj(error);
        // adobeDataLayer.onError(errorEventObj);

        if (errorRes.code === codes.JWT_EXPIRED) window.location.reload();

        if (errorRes.details?.rsaCookie)
          rsaCookie.set(errorRes.details.rsaCookie);

        if (errorRes.code === codes.INCORRECT_CREDENTIALS)
          return reject(
            'Your username/password combination isn’t correct. Password is case sensitive. Please try again.',
          );

        if (errorRes.code === codes.ACCOUNT_LOCKED)
          return reject(codes.ACCOUNT_LOCKED);

        if (errorRes.code === codes.INDETERMINANT_ERROR)
          return reject(codes.INDETERMINANT_ERROR);

        return reject(DEFAULT_ERROR);
      });
  });
};

// eslint-disable-next-line no-unused-vars
const generateAdobeEventObj = (error) => {
  const errorRes = error.response?.data || {};

  let errorType = '';
  if (errorRes.code === codes.JWT_EXPIRED) errorType = 'JWT_EXPIRED';
  else if (errorRes.code === codes.INCORRECT_CREDENTIALS)
    errorType = 'INCORRECT_CREDENTIALS';
  else if (errorRes.code === codes.ACCOUNT_LOCKED) errorType = 'ACCOUNT_LOCKED';
  else errorType = 'Something went wrong!';

  const errorCode = error.response?.status;
  const errorDescription = errorRes?.message || '';
  return { errorType, errorCode, errorDescription };
};

export const authenticateAnotherWay = (authvalue) => {
  return new Promise((resolve, reject) => {
    // Get sessionid from redux
    const { sessionid, ipAddress } = store.getState().session.value;

    // Get the URL
    const url = urls.AUTHENTICATE_ANOTHER_WAY;

    // Create the request body
    const requestBody = authenticateAnotherWayReq(
      sessionid,
      authvalue,
      ipAddress,
      rsaCookie,
    );

    // Make API call to backend to initialize the cache session
    axios
      .post(url, requestBody, {
        headers,
      })
      .then((response) => {
        if (response.data.details?.rsaCookie)
          rsaCookie.set(response.data.details.rsaCookie);

        if (response.data.details?.email && response.data.details?.mobile)
          store.dispatch(
            setContactInfo({
              email: response.data.details.email,
              mobile: response.data.details.mobile,
            }),
          );

        if (response.data.code === codes.SUCCESS) return resolve();

        reject(response.data.code);
      })
      .catch((error) => {
        if (error.response.data.code === codes.JWT_EXPIRED)
          window.location.reload();

        if (error.response.data.code === codes.INCORRECT_CREDENTIALS)
          return reject('Incorrect value entered, please try again.');

        if (error.response.data.code === codes.ACCOUNT_LOCKED)
          return reject(codes.ACCOUNT_LOCKED);

        return reject(DEFAULT_ERROR);
      });
  });
};

export const authenticateV2 = (sessionid, password, username) => {
  return new Promise((resolve, reject) => {
    const url = urls.AUTHENTICATE;

    // Create the request body
    const requestBody = authenticateReq(sessionid, password, username);

    // Make API call to backend to initialize the cache session
    axios
      .post(url, requestBody, { headers })
      .then((response) => {
        if (response.data.details?.rsaCookie) {
          rsaCookie.set(response.data.details.rsaCookie);
        }
        resolve(response.data);
      })
      .catch((error) => {
        const errorRes = error.response?.data;
        // const errorEventObj = generateAdobeEventObj(error);

        if (errorRes?.code === codes.JWT_EXPIRED) window.location.reload();

        if (errorRes?.details?.rsaCookie)
          rsaCookie.set(errorRes?.details?.rsaCookie);

        let endErrorRes = null;
        if (errorRes?.code) {
          const validErr = validateErrorCode(errorRes?.code);
          endErrorRes = validErr ? errorRes : manualError;
        } else {
          endErrorRes = manualError;
        }

        return reject(endErrorRes);
      });
  });
};

const validateErrorCode = (errorCode) => {
  const validErrors = ['0000', '0008', '0011', '4004', '5101', '5102', '5301'];
  return validErrors.includes(errorCode);
};
