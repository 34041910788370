import { createSlice } from '@reduxjs/toolkit';
import { authtypes } from '../../../constants';
import { DEFAULT_ERROR } from 'application/enum';
import {
  clickSigninAnotherWayAction,
  onSubmitSqaAction,
} from './signinAnotherWay.action';

const initialState = {
  isAnotherWayLoading: false,
  verifyAnotherWayStateError: null,
  verifyAnotherWayState: {
    enabled: false,
    maxAuthType: authtypes.PASSWORD,
    sqaIndices: [],
  },

  sqaAnswers: {},
  sqaErrors: {},
  isSqaDisabled: false,
  isSqaLoading: false,
};

export const signinAnotherWaySlice = createSlice({
  name: 'signinAnotherWay',
  initialState,
  reducers: {
    setVerifyAnotherWayState: (state, action) => {
      const dat = {
        ...state.verifyAnotherWayState,
        ...action.payload,
      };
      state.verifyAnotherWayState = dat;
    },
    setSqaAnswers: (state, action) => {
      state.sqaAnswers = action.payload;
    },
    setSqaErrors: (state, action) => {
      state.sqaErrors = action.payload;
    },
    setSqaDisabled: (state, action) => {
      state.isSqaDisabled = action.payload;
    },
    setSqaLoading: (state, action) => {
      state.isSqaLoading = action.payload;
    },

    manipulateMultiState: (state, action) => {
      const obj = action.payload;
      Object.keys(obj).forEach((key) => {
        state[key] = obj[key];
      });
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(clickSigninAnotherWayAction.pending, (state) => {
        state.isAnotherWayLoading = true;
      })
      .addCase(clickSigninAnotherWayAction.fulfilled, (state, action) => {
        state.isAnotherWayLoading = false;

        const data = {
          ...(state.value?.verifyAnotherWayState || {}),
          ...action.payload,
        };

        const { sqaIndices } = action.payload;
        const answers = Object.fromEntries(sqaIndices.map((ind) => [ind, '']));
        const errors = Object.fromEntries(sqaIndices.map((ind) => [ind, '']));

        console.log('my clickSigninAnotherWayAction', action.payload);
        state.value.verifyAnotherWayState = data;
        state.sqaAnswers = answers;
        state.sqaErrors = errors;
      })
      .addCase(clickSigninAnotherWayAction.rejected, (state, action) => {
        state.isAnotherWayLoading = false;
        const msg = action.error?.message || DEFAULT_ERROR;
        state.verifyAnotherWayStateError = msg;
      });

    builder
      .addCase(onSubmitSqaAction.pending, (state, action) => {
        state.isSqaLoading = true;
      })
      .addCase(onSubmitSqaAction.fulfilled, (state, action) => {
        state.isSqaLoading = false;
      })
      .addCase(onSubmitSqaAction.rejected, (state, action) => {
        state.isSqaLoading = false;
        const sqaErrors = action.payload; // checkHere
        state.sqaErrors = sqaErrors;
      });
  },
});

export const {
  setVerifyAnotherWayState,

  setSqaAnswers,
  setSqaErrors,
  setSqaDisabled,
  setSqaLoading,

  manipulateMultiState,
} = signinAnotherWaySlice.actions;
export const signinAnotherWaySelector = (state) => state.signinAnotherWay;
export default signinAnotherWaySlice.reducer;
