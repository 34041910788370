import axios from 'axios';
import { urls } from 'constants';
import { chatbotTokenReq } from './model/chatbotToken';

export const getChatbotToken = (chatBotLOB) => {
  return new Promise((resolve, reject) => {
    const url = urls.CHATBOT_TOKEN;

    axios
      .post(url, chatbotTokenReq(chatBotLOB))
      .then((response) => {
        if (response.data.code === '0000' && response.data.details) {
          return resolve(response.data.details);
        }
        return reject(response.data?.message);
      })
      .catch((error) => {
        return reject(error.response?.data);
      });
  });
};

export default getChatbotToken;
