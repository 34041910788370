// import { urls } from 'constants';

import { urls } from '../../../constants';
import FACEBOOK from 'images/facebook_f.png';
import YOUTUBE from 'images/youtube_small.png';
import TWITTER from 'images/twitter_small.png';
import INSTAGRAM from 'images/instagram_small.png';
import LINKEDIN from 'images/linkedin_small.png';
import { links } from 'constants/common';

export const RPSdisclosuresMock = [
  'John Hancock and the plan are not affiliated and neither is responsible for the liabilities of the other.',
  'The content of this website is for general information only and is believed to be accurate and reliable as of posting date but may be subject to change. John Hancock does not provide investment, tax, or legal advice. Please consult your own independent advisor as to any investment, tax, or legal statements made herein.',
  'All plan information is confidential and you agree to safeguard and protect such information in accordance with the standards required by law and use such information only for authorized plan administration purposes.',
  'John Hancock Retirement Plan Services LLC provides administrative and/or recordkeeping services to sponsors or administrators of retirement plans through an open-architecture platform. John Hancock Trust Company LLC, a New Hampshire non-depository trust company, provides trust and custodial services to such plans, offers an Individual Retirement Accounts product, and maintains specific Collective Investment Trusts. Group annuity contracts and recordkeeping agreements are issued by John Hancock Life Insurance Company (U.S.A.), Boston, MA (not licensed in NY), and John Hancock Life Insurance Company of New York, Valhalla, NY. Product features and availability may differ by state. All entities do business under certain instances using the John Hancock brand name. Each entity makes available a platform of investment alternatives to sponsors or administrators of retirement plans without regard to the individualized needs of any plan. Unless otherwise specifically stated in writing, each entity does not, and is not undertaking to, provide impartial investment advice or give advice in a fiduciary capacity. Securities are offered through John Hancock Distributors LLC, member FINRA, SIPC.',
  'NOT FDIC INSURED. MAY LOSE VALUE. NOT BANK GUARANTEED',
  '© 2025 All rights reserved.',
  'MGTS-P40162-GE ',
];

export const FOOTER_LABELS = {
  ACCESSIBILITY: 'Accessibility',
  ABOUT_US: 'About Us',
  LEGAL: 'Legal',
  PRIVACY: 'Privacy & Security',
  CORPORATE_WEBSITE: 'Corporate Website',
  ACCOUNT_SECURITY: 'Account Security',
  TOS: 'Terms of Service',
  CONTACT: 'Contact',
  FACEBOOK: 'Facebook',
  YOUTUBE: 'Youtube',
  TWITTER: 'Twitter',
  INSTAGRAM: 'Instagram',
  LINKEDIN: 'Linkedin',
};

export const jhLinks = [
  {
    label: FOOTER_LABELS.ACCESSIBILITY,
    link: 'http://www.johnhancock.com/accessibility.html',
  },
  { label: FOOTER_LABELS.LEGAL, link: 'http://www.johnhancock.com/legal.html' },
  {
    label: FOOTER_LABELS.PRIVACY,
    link: 'http://www.johnhancock.com/privacysecurity.html',
  },
];

export const mlbLinks = [
  {
    label: FOOTER_LABELS.ABOUT_US,
    link: 'https://www.manulifebermuda.com/about-us.html',
  },
  {
    label: FOOTER_LABELS.LEGAL,
    link: 'https://www.manulifebermuda.com/legal.html',
  },
  {
    label: FOOTER_LABELS.PRIVACY,
    link: 'https://www.manulifebermuda.com/privacy.html',
  },
];

export const ghnwLinks = [
  {
    label: FOOTER_LABELS.ACCESSIBILITY,
    link: 'https://client.manulifeghnw.com/ghnwclientportal/s/accessibility',
  },
  {
    label: FOOTER_LABELS.LEGAL,
    link: 'https://client.manulifeghnw.com/ghnwclientportal/s/legal',
  },
  {
    label: FOOTER_LABELS.PRIVACY,
    link: 'https://client.manulifeghnw.com/ghnwclientportal/s/privacy',
  },
];

export const dtcPILinks = [
  {
    label: FOOTER_LABELS.CONTACT,
    link: links.DTC_CONTACT_LINK,
  },
  {
    label: FOOTER_LABELS.ACCESSIBILITY,
    link: links.ACCESSIBILITY_JH_LINK,
  },
  {
    label: FOOTER_LABELS.PRIVACY,
    link: links.PRIVACY_JH_LINK,
  },
  { label: FOOTER_LABELS.TOS, link: links.TOS_JH_LINK },
];

export const rpsLinks = [
  {
    label: FOOTER_LABELS.LEGAL,
    link: urls.LEGAL_REDIRECT,
  },
  {
    label: FOOTER_LABELS.PRIVACY,
    link: urls.PRIVACY_REDIRECT,
  },
  {
    label: FOOTER_LABELS.ACCESSIBILITY,
    link: urls.ACCESSIBILITY_REDIRECT,
  },
  {
    label: FOOTER_LABELS.ACCOUNT_SECURITY,
    link: urls.ACCOUNTSECURITY_REDIRECT,
  },
  {
    label: FOOTER_LABELS.CORPORATE_WEBSITE,
    link: urls.CORPORATEWEBSITE_REDIRECT,
  },
];

export const socialsLinks = [
  {
    label: FOOTER_LABELS.FACEBOOK,
    link: links.FACEBOOK_JH_LINK,
    src: FACEBOOK,
  },
  {
    label: FOOTER_LABELS.YOUTUBE,
    link: links.YOUTUBE_JH_LINK,
    src: YOUTUBE,
  },
  {
    label: FOOTER_LABELS.TWITTER,
    link: links.TWITTER_JH_LINK,
    src: TWITTER,
  },
  {
    label: FOOTER_LABELS.INSTAGRAM,
    link: links.INSTAGRAM_JH_LINK,
    src: INSTAGRAM,
  },
  {
    label: FOOTER_LABELS.LINKEDIN,
    link: links.LINKEDIN_JH_LINK,
    src: LINKEDIN,
  },
];
export const rpsContactUs = urls.CONTACT_US_REDIRECT;
