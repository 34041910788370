import { DEFAULT_ERROR } from 'application/enum';
import axios from 'axios';
import { codes, urls } from 'constants';
import store from 'store';

export const getAuthType = () => {
  return new Promise((resolve, reject) => {
    // Get sessionid  from redux
    const { sessionid } = store.getState().session.value;

    // Get the URL
    const url = urls.GET_AUTH_TYPE;

    // Create the request body
    const requestBody = { session: sessionid };

    // Make API call to backend to initialize the cache session
    axios
      .post(url, requestBody, {
        headers: {
          'Content-Type': 'application/json',
        },
      })
      .then((response) => {
        if (response.data.code === codes.SUCCESS)
          return resolve(response.data.details);

        reject(response.data.code);
      })
      .catch((error) => {
        if (error.response.data.code === codes.JWT_EXPIRED)
          window.location.reload();

        return reject(DEFAULT_ERROR);
      });
  });
};
