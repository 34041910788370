export const ACCOUNT_LOCKED_HEADING =
  'We’ve locked your account for your security';

export const ACCOUNT_LOCKED_DESC1 =
  "For security purposes, we've locked your account. You've either exceeded the number of login attempts with an incorrect password or logged in too many times in a limited period of time.";
export const TO_UNLOCK_YOUR_ACCOUNT = 'To unlock your account';
export const PLEASE_CALL =
  'please call 1-888-266-7498, select option 2 then option 3.';

export const ACCOUNT_LOCKED_DESC3 =
  'please call 1-800-448-1616, select option 2 then option 3.';
export const ACCOUNT_LOCKED_DESC4 =
  'please call 1-877-339-5663, select option 1 then option 7.';

// NonPot user -> Error Scenario!
export const ACCESS_LOCKED_RESET_PASSWORD =
  'Your access to this secured site has been locked. To regain access, please reset your Password.';
export const ACCESS_LOCKED_RESET_PASSWORD2 =
  'Because you’ve exceeded the allowed number of sign-in attempts with the incorrect username/password, we’ve locked your account. We do this to help keep your account safe.';
export const INSTRUCTIONS_RESETTING_PASSWORD =
  'Instructions on resetting your password have been sent to your email address. Follow these instructions to reset your password and access your account. If you are still experiencing difficulty call 1-800-395-1113 to speak to a customer service representative between 8am and 8pm ET Monday to Friday.';

// nonpot sub descriptions
export const REGAIN_ACCESS_RESET_PASSWORD =
  'To regain access, please reset your password.';
