import React from 'react';
import { Grid } from '@mui/material';
import { layoutStyles as styles } from 'application/styles/layout.styles';

export const Layout = ({ children, sx }) => {
  const sxLoc = { ...styles.container, ...styles.flexedStyles, ...sx };
  return <Grid sx={sxLoc}>{children}</Grid>;
};

export const Layout2 = ({ children, sx }) => {
  return <Grid sx={{ ...styles.container2, ...sx }}>{children}</Grid>;
};

export const Layout3 = ({ children, sx }) => {
  return <Grid sx={{ ...styles.container, ...sx }}>{children}</Grid>;
};

export const MainSectionLayout = ({ children, sx }) => {
  return <Grid sx={{ ...styles.mainSection, ...sx }}>{children}</Grid>;
};
