import React from 'react';
import { useSelector } from 'react-redux';
import { MessageCard } from '@manulife/mux';
import { Box } from '@mui/material';
import { useSearchParams } from 'react-router-dom';
import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import { useGetMaintenanceMsgQuery } from 'store/reducers/maintenance/maintenance.action';

export const MaintenanceMessage = () => {
  const [searchParams] = useSearchParams();
  const appid = searchParams.get('appid');

  const { maintenanceMsgDomain } = useSelector(selectAppSettings);
  const { data: maintenanceMessages } = useGetMaintenanceMsgQuery({
    appid,
    domain: maintenanceMsgDomain,
  });
  const { isUnderMaintenance, message, description } =
    maintenanceMessages?.details || {};

  if (!isUnderMaintenance) return null;
  return (
    <Box sx={styles.ctn}>
      <MessageCard
        hasCloseButton
        variant={MessageCard.VARIANT.PRIMARY_WARNING}
        titleText={message}
        className="msg-bar"
      >
        {description}
      </MessageCard>
    </Box>
  );
};

const styles = {
  ctn: {
    position: 'fixed',
    top: 0,
    '& .msg-bar': {
      margin: '16px',
      marginLeft: {
        xs: '16px',
        sm: '96px',
      },
    },
  },
};
