const LOGIN_PAGE = ':login-page';
const LOGIN_MFA_PAGE = ':login:mfa-page';
const LOGIN_UPDATE_PROFILE_PAGE = ':login:update-profile-page';
const ACCOUNT_LOCKED_PAGE = ':account-locked-page';
const DEFAULT_ERROR_PAGE = ':default-error-page';

const activeAdobeAppsObj = {
  1104: true, // rps (myplan)
  27096999: true, // vitality
  27096405: true, // vitality
  DEFAULT: false,
};

const adobeUrlsObj = {
  1104: process.env.REACT_APP_ADOBE_RPS_URL,
  27096999: process.env.REACT_APP_ADOBE_VITALITY_URL, // vitality
  27096405: process.env.REACT_APP_ADOBE_VITALITY_URL, // vitality
  DEFAULT: '',
};

const pageNamesObj = {
  1104: 'RPS',
  27096999: 'insurance:vitality:johnhancockinsurance',
  27096405: 'insurance:vitality:johnhancockinsurance',
  DEFAULT: '',
};

const pageSubSectionNameObj = {
  1104: {
    login: LOGIN_PAGE,
    loginMfa: LOGIN_MFA_PAGE,
    loginUpdateProfile: LOGIN_UPDATE_PROFILE_PAGE,
    accountLocked: ACCOUNT_LOCKED_PAGE,
    defaultError: DEFAULT_ERROR_PAGE,
  },
  27096999: {
    accountLocked: ACCOUNT_LOCKED_PAGE,
    defaultError: DEFAULT_ERROR_PAGE,
  },
  27096405: {
    accountLocked: ACCOUNT_LOCKED_PAGE,
    defaultError: DEFAULT_ERROR_PAGE,
  },
  DEFAULT: {},
};

const handler = {
  get: (objArg, key) => objArg[key] || objArg.DEFAULT,
};

export const adobeActiveApps = new Proxy(activeAdobeAppsObj, handler);
export const adobeUrls = new Proxy(adobeUrlsObj, handler);
export const adobePageNames = new Proxy(pageNamesObj, handler);
export const pageSubSectionName = new Proxy(pageSubSectionNameObj, handler);
