import axios from 'axios';
import { urls } from 'constants';

const headers = {
  'Content-Type': 'application/json',
};

export const passwordValidation = async (req) => {
  try {
    const res = await axios.post(urls.VALIDATE_CREDENTIALS_URL, req, {
      headers,
    });
    return res;
  } catch (err) {
    return {
      message: 'Error in password validation',
      details: { err },
      // data: { code: codes.UNKNOWN_ERROR },
    };
  }
};
