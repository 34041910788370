import { createSlice } from '@reduxjs/toolkit';
import { apiVariable } from 'constants/common';

const initialState = {
  maintenanceMessages: { ...apiVariable },
};

export const maintenanceSlice = createSlice({
  name: 'maintenance',
  initialState,
  reducers: {},
});

// export const {} = maintenanceSlice.actions;
export const maintenanceSelector = (state) => state.maintenance;
export const maintenanceReducer = maintenanceSlice.reducer;
