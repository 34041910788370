import { createAsyncThunk } from '@reduxjs/toolkit';
import { logoutCiamApi } from 'api/logout.api';

export const logoutCIAMAction = createAsyncThunk(
  'globals/logout',
  async (payload, { getState, _dispatch }) => {
    try {
      const store = getState();

      const appid = payload?.appid;
      const { sessionid } = store.session.value;

      const res = await logoutCiamApi({ appid, session: sessionid });
      return res;
    } catch (error) {
      console.log('logout error', error);
    }
  },
);
