import { createSlice } from '@reduxjs/toolkit';

export const sessionSlice = createSlice({
  name: 'session',
  initialState: {
    value: {
      sessionid: null,
      ipAddress: '',
      email: '',
      mobile: '',
      username: '',
      password: '',
      chosenAuthType: 0,
      isMigratedUsr: true,
    },
    userDetails: {},
    isSessionLoading: false,
  },

  reducers: {
    setPassword: (state, action) => {
      state.value = {
        ...state.value,
        password: action.payload,
      };
    },
    setSessionid: (state, action) => {
      state.value = {
        ...state.value,
        sessionid: action.payload,
      };
    },
    setIPAddress: (state, action) => {
      state.value = {
        ...state.value,
        ipAddress: action.payload,
      };
    },
    setContactInfo: (state, action) => {
      state.value = {
        ...state.value,
        email: action.payload.email,
        mobile: action.payload.mobile,
      };
    },
    setLoginPageState: (state, action) => {
      state.value = {
        ...state.value,
        username: action.payload.username,
        chosenAuthType: action.payload.chosenAuthType,
      };
    },
    setChosenAuthType: (state, action) => {
      state.value = {
        ...state.value,
        chosenAuthType: action.payload,
      };
    },
    setSSOPageState: (state, action) => {
      state.value = {
        ...state.value,
        username: action.payload,
        ssoInitiated: true,
      };
    },
    setMigratedUser: (state, action) => {
      state.value = {
        ...state.value,
        isMigratedUsr: action.payload,
      };
    },
  },
});

export const {
  setSessionid,
  setIPAddress,
  setLoginPageState,
  setContactInfo,
  setChosenAuthType,
  setSSOPageState,
  setPassword,
  setMigratedUser,
} = sessionSlice.actions;

export const selectSession = (state) => state.session.value;
export const sessionSelector = (state) => state.session;
export default sessionSlice.reducer;
