import React from 'react';
import { Typography } from '@mui/material';

const BermudaDisclosure = ({ styles, year }) => {
  return (
    <>
      <Typography sx={styles.text}>
        © The Manufacturers Life Insurance Company 1999 – {year}
      </Typography>

      <Typography sx={styles.text}>
        Insurance products are issued by the Manufacturers Life Insurance
        Company (Bermuda Branch).
      </Typography>

      <Typography sx={styles.text}>
        O’Hara House, 3rd Floor 3 Bermudiana Road, Tower 2 (North Tower)
        Hamilton, Bermuda HM08.
      </Typography>

      <Typography sx={styles.text}>
        Guaranteed product features are dependent upon minimum premium
        requirements and the claims-paying ability of the issuer.
      </Typography>

      <Typography sx={styles.text}>
        Manulife and the Block Design are trademarks of The Manufacturers Life
        Insurance Company and are used by it, and by its affiliates under
        license.
      </Typography>
    </>
  );
};

export default BermudaDisclosure;
