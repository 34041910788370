export const brandings = {
  MANULIFE: 'MF',
  BERMUDA: 'MLB',
  JOHN_HANCOCK: 'JH',
  VITALITY: 'JHV',
  DTC_PI: "JHDTCPI"
};

export const appGroups = {
  SALESHUB_APPIDS: '27096401,10597976',
  BERMUDA_APPIDS: '27096404,27096464,27096465',
  GHNW_APPIDS: '27096464',
  VITALITY_APPIDS: '27096999,27096405',
  VITALITY_WEB_APPID: '27096999',
  RPS_APPIDS: '1104',
  SPRNT_APPIDS: '15482',
  DTC_PI_APPIDS: '2320147',
};
