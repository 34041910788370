import axios from 'axios';
import { urls } from 'constants';

export const getIP = (attempts = 3) => {
  return new Promise((resolve, reject) => {
    // Get the URL
    const url = urls.IPIFY;

    // Make API call to Ipify to get the user's IP Address
    axios
      .get(url)
      .then((response) => {
        resolve(response.data.ip);
      })
      .catch(() => {
        if (attempts === 0) return resolve('');

        getIP(attempts - 1)
          .then((res) => {
            return resolve(res);
          })
          .catch((err) => {
            return reject(err);
          });
      });
  });
};
