import React from 'react';
import { Typography } from '@mui/material';

const SaleshubDisclosure = ({ styles, year }) => {
  return (
    <>
      <Typography sx={styles.text}>
        This material is for Agent and Institutional / Broker – Dealer use only.
        Not for distribution or use with the public.
      </Typography>

      <Typography sx={styles.text}>
        Insurance products are issued by: John Hancock Life Insurance Company
        (U.S.A.), Boston, MA 02216 (not licensed in New York) and John Hancock
        Life Insurance Company of New York, Valhalla, NY 10595. Variable
        insurance products are offered through John Hancock Distributors LLC
        through other broker/dealers that have a selling agreement with John
        Hancock Distributors LLC, 197 Clarendon Street, Boston, MA 02116.
      </Typography>

      <Typography sx={styles.text}>
        Insurance policies and/or associated riders and features may not be
        available in all states.
      </Typography>

      <Typography sx={styles.text}>
        © {year} John Hancock Life Insurance Company (U.S.A.). All rights
        reserved.
      </Typography>
    </>
  );
};

export default SaleshubDisclosure;
