export const apiVariable = {
  error: null,
  loading: false,
  data: null,

  FORGOT_PASSWORD: 'forgotpassword',
};

export const messages = {
  INACTIVE_SESSION_MESSAGE:
    'inactive sessions will close after 15 minutes and any unsaved data could be lost. Access and use of this website is for authorized users only.',
  PLEASE_NOTE_MESSAGE: 'Please note:',
  CONTACT_MESSAGE: 'Contact',
  NEED_ASSISTANCE_MESSAGE: 'If you need assistance',
  DTC_DISCLOSURE_FIRST_MESSAGE:
    'Investing involves risks, including the potential loss of principal. These products carry many individual risks, including some that are unique to each fund. Please see each fund’s prospectus to learn all of the risks associated with each investment.',
  DTC_DISCLOSURE_SECOND_MESSAGE:
    'Securities are offered through Manulife John Hancock Brokerage Services LLC (MJHBS), member FINRA/MSRB/SIPC and John Hancock Distributors LLC, member FINRA, SIPC. Brokerage services are offered through Manulife John Hancock Brokerage Services LLC (MJHBS). John Hancock Investment Management Distributors LLC is the principal underwriter and wholesale distribution broker-dealer for the John Hancock mutual funds, member FINRA, SIPC. 200 Berkeley Street, Boston MA, 02116.',
  NOT_FDIC_INSURED_MESSAGE:
    'NOT FDIC INSURED. MAY LOSE VALUE. NOT BANK GUARANTEED.',
  DTC_DISCLOSURE_MESSAGE:
    'Manulife, Manulife Wealth, Stylized M Design, and Manulife Wealth & Stylized M Design are trademarks of The Manufacturers Life Insurance Company, and John Hancock and the Stylized John Hancock Design are trademarks of John Hancock Life Insurance Company (U.S.A.). Each are used by it and by its affiliates under license.',
};

export const labels = {
  IRAS_LABEL: 'IRAs',
};

export const links = {
  DTC_CONTACT_LINK: 'https://digital.customer.johnhancock.com/help/contact',
  ACCESSIBILITY_JH_LINK: 'http://www.johnhancock.com/accessibility.html',
  PRIVACY_JH_LINK: 'https://www.johnhancock.com/privacy-security.html',
  TOS_JH_LINK: 'http://www.johnhancock.com/legal.html',
  FACEBOOK_JH_LINK: 'https://www.facebook.com/johnhancock/',
  YOUTUBE_JH_LINK: 'https://www.youtube.com/channel/UCjLx1uO0Rhi1H00BFDpvshw',
  TWITTER_JH_LINK:
    'https://x.com/johnhancockusa?ref_src=twsrc%5Egoogle%7Ctwcamp%5Eserp%7Ctwgr%5Eauthor',
  INSTAGRAM_JH_LINK: 'https://www.instagram.com/johnhancock/?hl=en',
  LINKEDIN_JH_LINK: 'https://www.linkedin.com/company/john-hancock/',
};
