import { createSlice } from '@reduxjs/toolkit';

export const appSettingsSlice = createSlice({
  name: 'appSettings',
  initialState: {
    value: {
      appid: null,
      branding: null,
      CodeExpiry: null,
      registrationURL: null,
      fuidURL: null,
      fpwdURL: null,
      VoiceCallStatus: null,
      TextMessageStatus: null,
      contact: null,
      contactHours: null,
      chatbotLOB: null,
      chatbotAppName: null,
      maintenanceMsgDomain: null,
      backURL: null,
      defaultURL: null,
      homeUrl: null,
      unlockAccountUrl: null,
      se2Url: null,
    },
  },
  reducers: {
    setAppId: (state, action) => {
      localStorage.setItem('appid', action.payload);
      state.value = {
        ...state.value,
        appid: action.payload,
      };
    },
    setAppSettings: (state, action) => {
      const data = {
        ...state.value,
        ...action.payload,
      };
      localStorage.setItem('appid', data.appid);
      state.value = data;
    },
  },
});

export const { setAppSettings, setAppId } = appSettingsSlice.actions;

export const selectAppSettings = (state) => state.appSettings.value;

export default appSettingsSlice.reducer;
