import React from 'react';
import { useSelector } from 'react-redux';
import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import { Typography } from '@mui/material';

const VitalityDisclosure = ({ styles }) => {
  const { contact, contactHours } = useSelector(selectAppSettings);

  return (
    <>
      <Typography sx={styles.text}>
        Access and use of this website is for authorized users only. If you have
        any questions regarding your policy or registration, please contact a
        John Hancock Vitality representative at{' '}
        <strong style={{ ...styles.text, fontWeight: '600' }}>{contact}</strong>{' '}
        {contactHours}
      </Typography>

      <Typography sx={styles.text}>
        Vitality is the provider of the John Hancock Vitality Program in
        connection with policies issued by John Hancock. Insurance products are
        issued by John Hancock Life Insurance Company (U.S.A.), Boston, MA 02210
        (not licensed in New York) and John Hancock Life Insurance Company of
        New York, Valhalla, NY 10595.
      </Typography>

      <Typography sx={{ ...styles.text }}>MLINY062718203</Typography>
    </>
  );
};

export default VitalityDisclosure;
