const padding = {
  xs: '50px 20px 60px 20px',
  sm: '60px',
};

export const layoutStyles = {
  container: {
    padding,
    minHeight: {
      sm: 'calc(100vh - 162px)',
      xs: '480px',
    },
  },
  flexedStyles: {
    display: 'flex',
    flexDirection: {
      xs: 'column',
      lg: 'row',
    },
    justifyContent: 'flex-start',
    gap: {
      xs: '0px',
      lg: '60px',
    },
  },
  container2: {
    display: 'flex',
    flexDirection: 'column',
    padding,
  },

  mainSection: {
    flexBasis: '50%',
    maxWidth: '590px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'baseline',
    gap: '40px',
  },
};
