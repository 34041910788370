import {
  ACCOUNT_LOCKED_HEADING,
  COMMON_SIGNIN_HEADING,
  DEFAULT_TAB_FAV_ICON_PATH,
  DEFAULT_TAB_TITLE,
  RPS_SIGNIN_HEADING,
  SQN_SSN_SIGNIN_HEADING,
  UPDATE_PROFILE_TITLE,
  VITALITY_TAB_FAV_ICON_PATH,
  VITALITY_TAB_TITLE,
  RPS_TAB_FAV_ICON_PATH,
} from 'application/enum';
import { VERIFY_YOUR_IDENTITY } from 'application/enum/mfa.enum';

// Login.js
const loginPageTitleObj = {
  DEFAULT: COMMON_SIGNIN_HEADING,
  15482: COMMON_SIGNIN_HEADING,
  1104: RPS_SIGNIN_HEADING,
};

// SignInAnotherWay.js
const signinAnotherWayTitleObj = {
  DEFAULT: SQN_SSN_SIGNIN_HEADING,
  // 1104: add when required,
};

// mfa
const mfaTitleObj = {
  DEFAULT: VERIFY_YOUR_IDENTITY,
  // 1104: add when required,
};

// UpdateProfileTitleObj
const updateProfileTitleObj = {
  15482: UPDATE_PROFILE_TITLE,
  1104: UPDATE_PROFILE_TITLE,
  DEFAULT: UPDATE_PROFILE_TITLE,
};

// AccountLockedPage
const accountLockedTitleObj = {
  15482: ACCOUNT_LOCKED_HEADING,
  1104: ACCOUNT_LOCKED_HEADING,
  DEFAULT: ACCOUNT_LOCKED_HEADING,
};

// Tab Title
const tabTitle = {
  27096999: VITALITY_TAB_TITLE,
  DEFAULT: DEFAULT_TAB_TITLE,
};

// Tab Title
const tabIcon = {
  27096999: VITALITY_TAB_FAV_ICON_PATH,
  1104: RPS_TAB_FAV_ICON_PATH,
  DEFAULT: DEFAULT_TAB_FAV_ICON_PATH,
};

const handler = {
  get: (objArg, key) => objArg[key] || objArg.DEFAULT,
};

// this proxy will create an object.
// this logic is necessary if appid is not sent, then the default value shall be returned
export const loginPageTitles = new Proxy(loginPageTitleObj, handler);
export const signinAnotherWayTitles = new Proxy(
  signinAnotherWayTitleObj,
  handler,
);
export const mfaTitles = new Proxy(mfaTitleObj, handler);
export const updateProfileTitles = new Proxy(updateProfileTitleObj, handler);
export const accountLockedTitles = new Proxy(accountLockedTitleObj, handler);
export const broswerTabTitle = new Proxy(tabTitle, handler);
export const broswerTabIcon = new Proxy(tabIcon, handler);
