import React, { useState, useEffect } from 'react';
import { Box } from '@mui/material';
import { JohnHancock, Manulife } from '@manulife/mux-logos';
import { useSelector } from 'react-redux';

import JHVLogoBlue from 'images/JHV_Logo_Blue.png';
import JHVLogoWhite from 'images/JHV_Logo_White.png';
import DTCPILogoColor from 'images/Manulife_John_Hancock_Wealth.png';
import DTCPILogoWhite from 'images/Manulife_John_Hancock_Wealth_Reverse.png';

// import JHLogoBlue from 'images/JH_Logo_Blue.svg';
// import JHLogoWhite from 'images/JH_Logo_White.png';
// import MFLogoGreen from 'images/MF_Logo_Green.png';
// import MFLogoWhite from 'images/MF_Logo_White.png';

import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';
import { brandings } from 'constants/brandings';
import useAppids from 'hooks/useAppids';
import Utility from 'utils/Utility';
import { selectWindow, setWidth } from 'store/reducers/functions/window.slice';
import store from 'store';

const Header = () => {
  const { branding, defaultURL, homeUrl, logoutURL } =
    useSelector(selectAppSettings);
  const { isRpsAppid, isVitalityAppid } = useAppids();
  const [homeLogoUrl, setHomeLogoUrl] = useState('');

  const { width } = useSelector(selectWindow);

  useEffect(() => {
    const handleResize = () => {
      store.dispatch(setWidth(window.innerWidth));
    };
    handleResize();
    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  useEffect(() => {
    let redirectUrl;
    if (
      (Utility.isVitalityDomain || isVitalityAppid) &&
      window.location.pathname === '/auth/intermediatelogin'
    ) {
      // in case of vitality decision page thne we have to logout user and back to login page
      redirectUrl = logoutURL;
    } else if (isRpsAppid) {
      redirectUrl = homeUrl;
    } else {
      redirectUrl = defaultURL;
    }

    setHomeLogoUrl(redirectUrl);
  }, [isVitalityAppid, isRpsAppid, logoutURL, homeUrl, defaultURL]);

  const backToHome = () => {
    window.location.href = homeLogoUrl;
  };

  const generateNormalLogo = () => {
    if (width > 600)
      return (
        <JohnHancock.COLOR
          alt="John Hancock Logo"
          style={styles.logo}
          onClick={backToHome}
        />
      );
    else
      return (
        <JohnHancock.WHITE
          alt="John Hancock Logo"
          style={styles.logo}
          onClick={backToHome}
        />
      );
  };

  const generateMFLogo = () => {
    if (width > 600)
      return (
        <Manulife.COLOR
          alt="Manulife Logo"
          style={styles.logo}
          onClick={backToHome}
        />
      );
    else
      return (
        <Manulife.WHITE
          alt="Manulife Logo"
          style={styles.logo}
          onClick={backToHome}
        />
      );
  };

  return (
    <Box sx={styles.container}>
      {branding === brandings.MANULIFE || branding === brandings.BERMUDA ? (
        generateMFLogo()
      ) : branding === brandings.VITALITY ? (
        // Johnhancock Vitality logo is not included in mux
        <img
          src={width > 600 ? JHVLogoBlue : JHVLogoWhite}
          style={styles.logo}
          alt="John Hancock Vitality Logo"
          onClick={backToHome}
        />
      ) : branding === brandings.DTC_PI ? (
        <img
          src={width > 600 ? DTCPILogoColor : DTCPILogoWhite}
          style={styles.logoDtc}
          alt="Manulife Logo"
          onClick={backToHome}
        />
      ) : (
        generateNormalLogo()
      )}
    </Box>
  );
};

export default Header;

const styles = {
  container: {
    height: '80px',
    paddingLeft: {
      xs: '0px',
      sm: '60px',
    },
    backgroundColor: {
      xs: '#282B3E',
      sm: '#FAFAFA',
    },
    display: 'flex',
    justifyContent: {
      xs: 'center',
      sm: 'flex-start',
    },
  },
  logo: {
    maxHeight: '53px',
    paddingTop: '20px',
    paddingBottom: '20px',
    cursor: 'pointer',
    maxWidth: '265px',
    height: '53px',
  },
  logoDtc: {
    maxHeight: '53px',
    marginTop: '22px',
    cursor: 'pointer',
    maxWidth: '265px',
    marginBottom: '22px',
  },
};
