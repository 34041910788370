import devicePrint from 'utils/scripts/rsa.js';
import { rsaCookie, deviceTokenCookie } from 'utils/storage';

const cookie = rsaCookie.get();

export const initializeReq = (appid, url, dlfwd = null) => {
  return {
    appid,
    url,
    dlfwd,
  };
};

export const initializeWithJWTReq = (appid, jwt) => {
  return {
    appid,
    token: jwt,
  };
};

export const initializeMfaRsaReq = (appid, url, ipAddress) => {
  const deviceTokenCookiePrivate = deviceTokenCookie.get();

  return {
    appid,
    url,
    deviceRequest: {
      devicePrint,
      ipAddress,
      userAgent: navigator.userAgent,
      rsaCookie: cookie,
      deviceTokenCookie: deviceTokenCookiePrivate,
    },
  };
};
