import axios from 'axios';
import { urls } from 'constants';
// import store from 'store';

const headers = {
  'Content-Type': 'application/json',
};

export const thresholdAPI = (appid, SyestemID) => {
  return new Promise((resolve, reject) => {
    // Get the URL
    const url = urls.INITIALIZE_THRESHOLD;

    // Create the request body
    // Make API call to backend to initialize the threshold response
    const thresholdreq = {
      appid,
    };
    axios
      .post(url, thresholdreq, {
        headers,
        timeout: 10000,
      })
      .then((response) => {
        return resolve(response?.data?.detailedMessage?.data || null);
      })
      .catch((error) => {
        return resolve(null);
      });
  });
};
