import { appGroups } from 'constants/brandings';
import {
  broswerTabIcon,
  broswerTabTitle,
} from 'application/configurations/pageTitle.config';

function openLink(url) {
  window.open(url, '_self');
}

function isVitalityDomain() {
  // if (window.location.origin.endsWith('localhost:3000')) { // Comment this line only when to deploy
  if (window.location.origin.endsWith('johnhancockvitality.com')) {
    return true;
  } else {
    return false;
  }
}

function getFavIcon(appid) {
  let isVitality =
    isVitalityDomain() || appGroups.VITALITY_APPIDS.split(',').includes(appid);
  if (isVitality) {
    return broswerTabIcon[appGroups.VITALITY_WEB_APPID];
  } else {
    return broswerTabIcon[appid];
  }
}

function getTabTitle(appid) {
  return broswerTabTitle[appid];
}

const Utility = {
  openLink,
  isVitalityDomain,
  getFavIcon,
  getTabTitle,
};

export default Utility;

// Test git commit
