import axios from 'axios';
import { urls } from '../constants';

export const logoutCiamApi = async (body) => {
  try {
    const url = urls.LOGOUT_CIAM;
    return await axios.post(url, body);
  } catch (error) {
    throw new Error(error);
  }
};
