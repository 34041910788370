import React from 'react';
import { Navigate, useLocation } from 'react-router-dom';

export const NavigateWithQuery = ({ children, to, ...props }) => {
  const { search } = useLocation();

  return (
    <Navigate to={to + search} {...props}>
      {children}
    </Navigate>
  );
};
