import axios from 'axios';
import { codes, urls } from 'constants';
import store from 'store';

const headers = {
  'Content-Type': 'application/json',
};

export const authisamResetPassword = (newObj) => {
  return new Promise((resolve, reject) => {
    // Get sessionid from redux
    const { sessionid } = store.getState().session.value;

    // Get the URL
    const url = urls.ISAM_PASSWORD_RESET;

    // Create the request body
    // Make API call to backend to initialize the cache session
    const isamPasswordReq = {
      ...newObj,
      session: sessionid,
    };
    axios
      .post(url, isamPasswordReq, {
        headers,
      })
      .then((response) => {
        resolve(response.data);
      })
      .catch((error) => {
        if (error.code === codes.JWT_EXPIRED) window.location.reload();
        console.log('Error from create user when conflict', error);
        reject(error);
      });
  });
};
