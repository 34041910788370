// import store from 'store';
import { Cookies } from 'react-cookie';
import { storageNames } from 'constants/storageNames';

// Prepare cookies object
const cookies = new Cookies();

// Get the storage item name for this appid
const storageName = (type) => {
  // let { appid } = store.getState().appSettings.value;
  let appid = localStorage.getItem('appid');

  if (!appid) {
    const urlParams = new URLSearchParams(window.location.search);
    appid = urlParams.get('appid');
  }

  return `${type}_${appid}`;
};

// LocalStorage
const local = {
  // Set the storage item to a new value
  set: (type, value) => localStorage.setItem(storageName(type), value),

  // Get the current value of the storage item
  get: (type) => localStorage.getItem(storageName(type)),

  // Get the current value of the storage item
  remove: (type) => localStorage.removeItem(storageName(type)),
};

// SessionStorage
const session = {
  // Set the storage item to a new value
  set: (type, value) => sessionStorage.setItem(storageName(type), value),

  // Get the current value of the storage item
  get: (type) => sessionStorage.getItem(storageName(type)),

  // Get the current value of the storage item
  remove: (type) => sessionStorage.removeItem(storageName(type)),
};

// Cookies
const cookie = {
  // Set the storage item to a new value
  set: (type, value, options = {}) =>
    cookies.set(storageName(type), value, options),

  // Get the current value of the storage item
  get: (type, options = {}) => cookies.get(storageName(type), options),

  // Get the current value of the storage item
  remove: (type) => cookie.remove(storageName(type)),
};

export const rsaCookie = {
  // Set the cookie to a new value
  set: (value) => {
    let date = new Date();
    date.setFullYear(date.getFullYear() + 1);

    cookies.set(storageName(storageNames.RSA_COOKIE), value, {
      expires: date,
    });
  },

  // Get the current value of the cookie
  get: () => {
    return cookies.get(storageName(storageNames.RSA_COOKIE));
  },
};

export const deviceTokenCookie = {
  // Get the current value of the cookie
  get: () => {
    return cookies.get(storageNames.DEVICE_TOKEN_COOKIE);
  },
};

export default {
  local,
  session,
  cookie,
};
