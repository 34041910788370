import React from 'react';
import LoadingOverlay from '@ronchalant/react-loading-overlay';
import { loadOverlayStyles } from '../../styles/loaders.styles';
import { PLEASE_WAIT } from 'application/enum';

const LoadOverlay = ({ overlay }) => {
  return (
    <LoadingOverlay
      active={true}
      spinner
      text={PLEASE_WAIT}
      styles={{
        spinner: (base) => ({
          ...base,
          ...loadOverlayStyles.spinnerStyles,
        }),
        overlay: (base) => ({
          ...base,
          ...loadOverlayStyles.overlayStyles,
        }),
      }}
    ></LoadingOverlay>
  );
};

export default LoadOverlay;
