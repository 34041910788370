import axios from 'axios';

// This retry will gets triggered for response status other than 200.
// There will be some cases where we get 400 with correct response as below
// invalid mobile or invalid password will return 400 where we don't want to retry
// so thoughtfull before using this. Currently this is getting used in selected api's only

const httpRetry = axios.create();
httpRetry.interceptors.response.use(
  (response) => response,
  async (error) => {
    const config = error.config;

    if (!config._retryCount) {
      config._retryCount = 0;
    }
    const maxRetries = 2;
    if (config._retryCount < maxRetries) {
      config._retryCount += 1;
      const waitFor = new Promise((resolve) => {
        setTimeout(() => {
          resolve();
        }, 1000); // retry  with 1 second delay
      });
      await waitFor;

      return httpRetry(config);
    }

    return Promise.resolve(error);
  },
);

export default httpRetry;
