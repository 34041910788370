import { appGroups } from 'constants/brandings';
import { useSelector } from 'react-redux';
import { selectAppSettings } from 'store/reducers/appSettings/appSettings.slice';

export const generateAppid = (appidArg) => {
  const urlQueryparams = window.location.search;
  const queryparams = new URLSearchParams(urlQueryparams);
  const appidFromQuery = queryparams.get('appid');

  const appid = appidArg || appidFromQuery;

  const configObj = {
    appid,
    isRpsAppid: appGroups.RPS_APPIDS.split(',').includes(appid),
    isSalesHubAppid: appGroups.SALESHUB_APPIDS.split(',').includes(appid),
    isBermudaAppid: appGroups.BERMUDA_APPIDS.split(',').includes(appid),
    isGHNWAppid: appGroups.GHNW_APPIDS.split(',').includes(appid),
    isVitalityAppid: appGroups.VITALITY_APPIDS.split(',').includes(appid),
    isSprntAppid: appGroups.SPRNT_APPIDS.split(',').includes(appid),
    isDTCPIAppid: appGroups.DTC_PI_APPIDS.split(',').includes(appid),
  };

  return configObj;
};

const useAppids = () => {
  const { appid } = useSelector(selectAppSettings);

  const {
    isRpsAppid,
    isSalesHubAppid,
    isBermudaAppid,
    isGHNWAppid,
    isVitalityAppid,
    isSprntAppid,
    isDTCPIAppid,
    ...args
  } = generateAppid(appid);

  return {
    appid: args.appid,
    isRpsAppid,
    isSalesHubAppid,
    isBermudaAppid,
    isGHNWAppid,
    isVitalityAppid,
    isSprntAppid,
    isDTCPIAppid,
  };
};

export default useAppids;
