import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  defaultError: {},
  accountLocked: {},
  notAuthorize: {},
  error: null,
};

export const errorsSlice = createSlice({
  name: 'errors',
  initialState,
  reducers: {
    updateDefaultErr: (state, action) => {
      state.defaultError = action.payload;
    },
    updateAccountLockedErrors: (state, action) => {
      state.accountLocked = action.payload;
    },
    updateNotAuthorizedErrors: (state, action) => {
      state.notAuthorize = action.payload;
    },
    updateError: (state, action) => {
      state.error = action.payload;
    },
  },
});

export const {
  updateDefaultErr,
  updateAccountLockedErrors,
  updateNotAuthorizedErrors,
  updateError,
} = errorsSlice.actions;

export const errorsSelector = (state) => state.errors;
export default errorsSlice.reducer;
